import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { AccumulationVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import { checkExportFileAPI, deleteAccumulationAPI, exportAccumulationAPI } from 'helpers/backend_helper'
import {
  AccumulationIndustrySector,
  AccumulationCategory,
  AccumulationModel,
  Serial,
  Zone,
  StatusAccumulation,
  Shop,
  ActivatedDate,
  LongLatToDMS
} from './ManageAccumulationCol'
import AddEditAccumulation from './components/AddEditAccumulation'
import { getAccumulationDetail, getAccumulationList, setFilter, setTypePopupAccumulation } from 'store/actions'
import moment from 'moment'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import { ACCUMULATED_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import { Space } from 'antd'
import { TableRowSpan } from 'components/Common/TableCustom'
import ManageHistoryFilter from 'pages/ManageHistory/components/ManageHistoryFilter'
import ManageAccumulationFilter from './components/ManageAccumulationFilter'

function ManageAccumulation() {
  //meta title
  document.title = 'Accumulation List | HPG'

  const dispatch = useDispatch()
  const screenCode = useMemo(() => SCREEN_CODE.ACCUMULATED_MANAGEMENT, [])

  const selectAccumulationsState = (state) => state.AccumulationReducer
  const AccumulationsProperties = createSelector(selectAccumulationsState, (reducer) => ({
    accumulations: reducer.accumulations,
    loading: reducer.loading,
    total: reducer.total
  }))

  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))
  const { profile } = useSelector(ProfileProperties)

  const { accumulations, loading, total } = useSelector(AccumulationsProperties)

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (layout) => ({
    filter: layout.filter
  }))
  const { filter } = useSelector(LayoutProperties)

  const [isLoading, setLoading] = useState(loading)
  const [showDeleteAccount, setShowDeleteAccount] = useState(false)
  const [selected, setSelected] = useState()
  const [showForm, setShowForm] = useState(false)
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })

  useEffect(() => {
    dispatch(setFilter({ page: 1 }))
    dispatch(getAccumulationList({ page: 1 }))
  }, [dispatch])

  const onClickDelete = (item) => {
    setSelected(item)
    setShowDeleteAccount(true)
  }

  const onClickItem = async (item, type) => {
    setSelected(item)
    dispatch(setTypePopupAccumulation(type))
    dispatch(getAccumulationDetail(item))
    setShowForm(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: AccumulationVN.table.shop,
        accessor: 'usersShopName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Shop {...cellProps} />
        }
      },
      {
        Header: 'Ngành',
        accessor: 'industryName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Zone {...cellProps} />
        }
      },
      {
        Header: 'Ngành hàng',
        accessor: 'industrySectorName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Zone {...cellProps} />
        }
      },
      {
        Header: 'Nhóm sản phẩm',
        accessor: 'categoryName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Zone {...cellProps} />
        }
      },
      {
        Header: AccumulationVN.table.model,
        accessor: 'modelName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <AccumulationModel {...cellProps} />
        }
      },
      {
        Header: AccumulationVN.table.serial,
        accessor: 'productSerial',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Serial {...cellProps} />
        }
      },
      {
        Header: AccumulationVN.table.activatedDate,
        accessor: 'userRewardCreatedAt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ActivatedDate {...cellProps} />
        }
      },
      {
        Header: 'Vị trí',
        accessor: (cell) => {
          return cell
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <LongLatToDMS {...cellProps} />
        }
      },
      {
        Header: AccumulationVN.table.status,
        accessor: 'status',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <StatusAccumulation {...cellProps} />
        }
      },
      {
        Header: 'Tổng',
        accessor: 'countUserReward',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Zone {...cellProps} />
        }
      },
      {
        Header: AccumulationVN.table.action,
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (
            <ul className='list-unstyled hstack gap-1 mb-0'>
              <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                <Link
                  to={`#`}
                  className='btn btn-sm btn-soft-primary'
                  id={`viewtooltip-${cellProps.row.original.id}`}
                  onClick={() => {
                    onClickItem(cellProps.row.original, 'view')
                  }}
                >
                  <i className='mdi mdi-eye-outline' />
                  <UncontrolledTooltip
                    trigger='hover'
                    placement='top'
                    target={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    Xem
                  </UncontrolledTooltip>
                </Link>
              </li>

              <PermissionWrapper
                screenCode={screenCode}
                code={ACCUMULATED_MANAGEMENT_CODE.ADMIN_DELETE_USER_REWARD_ACCUMULATED}
              >
                <li>
                  <Link
                    to='#'
                    className='btn btn-sm btn-soft-danger'
                    onClick={() => {
                      onClickDelete(cellProps.row.original)
                    }}
                    id={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    <i className='mdi mdi-delete-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`deletetooltip-${cellProps.row.original.id}`}
                    >
                      Xóa
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </PermissionWrapper>
            </ul>
          )
        }
      }
    ],
    [filter]
  )

  const setGlobalFilterUser = async (data) => {
    if (data.start_date1 !== undefined && data.end_date1 !== undefined) {
      data.start_date = data.start_date1.startOf('day').toDate()
      data.end_date = data.end_date1.endOf('day').toDate()
    } else {
      data.start_date = undefined
      data.end_date = undefined
    }

    dispatch(
      setFilter({
        ...data,
        page: 1
      })
    )

    dispatch(
      getAccumulationList({
        ...data,
        meta: {
          Ngành: data?.industry_names,
          'Ngành hàng': data?.industry_sector_names,
          'Nhóm sản phẩm': data?.category_names,
          'Nhập Model': data?.model_names,
          'Nhập Serial': data?.serial,
          'Nhập tên cửa hàng': data?.shop_name,
          'Số điện thoại': data?.phone,
          'Loại tài khoản': data?.client_namme,
          Miền: data?.zone_names,
          Vùng: data?.sub_zone_names,
          'Tỉnh/ Thành phố': data?.province_names,
          'Quận/ Huyện': data?.district_names,
          'Phường xã': data?.ward_names,
          'Trạng thái': data?.status_name,
          'Tính tổng': data?.group_by_name,
          'Thời gian tích luỹ':
            data?.start_date1?.startOf('day').toDate() + ' - ' + data?.end_date1?.endOf('day').toDate()
        }
      })
    )
  }

  const goToPage = (page) => {
    dispatch(getAccumulationList({ ...filter, page }))
    setPage(page)
  }

  const onDeleteAccount = async () => {
    try {
      console.log('selected', selected)
      const res = await deleteAccumulationAPI({
        id: selected.userRewardId
      })
      if (res) {
        toast('Xóa thông tin tích lũy thành công', { type: 'success' })
        setShowDeleteAccount(false)
        dispatch(getAccumulationList({ ...filter, page }))
        setPage(page)
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleClickExport = async () => {
    setLoading(true)
    try {
      const res = await exportAccumulationAPI(filter)
      if (res?.data?.success === false) {
        toast(res?.message, { type: 'error' })
      } else {
        toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
          type: 'success'
        })
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
    setLoading(false)
  }

  const handleResetFilter = () => {
    dispatch(setFilter({ page: 1 }))
    dispatch(getAccumulationList({ page: 1 }))
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{AccumulationVN.title}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{AccumulationVN.list}</h5>
                      <div className='flex-shrink-0'>
                        <PermissionWrapper
                          screenCode={screenCode}
                          code={ACCUMULATED_MANAGEMENT_CODE.ADMIN_EXPORT_ACCUMULATED}
                        >
                          <button onClick={() => handleClickExport()} className='btn btn-primary me-2 pe-4'>
                            <i className='mdi mdi-database-export me-1 ms-2' />
                            {AccumulationVN.export}
                          </button>
                        </PermissionWrapper>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <Space size='middle' direction='vertical' className='w-100'>
                      <ManageAccumulationFilter
                        handleFilter={setGlobalFilterUser}
                        handleResetFilter={handleResetFilter}
                      />
                      <TableRowSpan
                        columns={columns}
                        data={accumulations}
                        pagination={pagination}
                        setPagination={setPagination}
                        gotoPageCustom={goToPage}
                        currentPage={page}
                        total={total}
                        isShowTotal={true}
                      />
                    </Space>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ConfirmModal
        show={showDeleteAccount}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-alert'></i>
          </div>
        }
        onConfirmClick={onDeleteAccount}
        onCloseClick={() => setShowDeleteAccount(false)}
        title='Xóa bản ghi'
        description={`Các kết quả thưởng của bản ghi sẽ bị hủy.\n Bạn có chắc chắn muốn xoá bản ghi tích lũy?`}
      />
      <AddEditAccumulation
        onCloseClick={() => {
          setShowForm(false)
        }}
        show={showForm}
        page={page}
      />
    </React.Fragment>
  )
}

export default ManageAccumulation
