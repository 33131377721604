import { GuestVN } from 'constants/vn'
import React, { useEffect, useMemo, useState } from 'react'
import { Col, Label, Modal, ModalBody, Row } from 'reactstrap'

// Formik validation
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { getGuestList, setTypePopupActivation } from 'store/actions'
import { getProvinceAPI, getDistrictAPI, getWardAPI, updateGuestAPI } from 'helpers/backend_helper'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { Form } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { debounce, isEqual } from 'lodash'
import { MAX_LENGTH_50 } from 'constants/constants'
import ConfirmModal from 'components/Common/ConfirmModal'

const AddEditGuest = ({ show, onCloseClick, selected }) => {
  const [form] = Form.useForm()
  const watchForm = Form.useWatch([], form)
  const [submittable, setSubmittable] = useState()
  const [initData, setInitData] = useState({})
  const [subZone, setSubZone] = useState([])
  const [district, setDistrict] = useState([])
  const [ward, setWard] = useState([])
  const [onCancel, setOnCancel] = useState(false)

  const dispatch = useDispatch()

  const selectGuestsState = (state) => state.GuestReducer
  const GuestsProperties = createSelector(selectGuestsState, (reducer) => ({
    type: reducer.type,
    guestDetail: reducer.guestDetail
  }))
  const { type, guestDetail } = useSelector(GuestsProperties)

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (layout) => ({
    filter: layout.filter
  }))
  const { filter } = useSelector(LayoutProperties)

  const isEdit = type !== 'view'

  useEffect(() => {
    handleGetSubZone()
    handleGetDistrict()
    handleGetWard()
  }, [])

  useEffect(() => {
    if (guestDetail) {
      form.setFieldsValue({
        name: guestDetail.name,
        phone: guestDetail.phone,
        subzone: guestDetail.provinceId,
        district: guestDetail.districtId,
        ward: guestDetail.wardId,
        address: guestDetail.address
      })

      setInitData({
        name: guestDetail.name,
        phone: guestDetail.phone,
        subzone: guestDetail.provinceId,
        district: guestDetail.districtId,
        ward: guestDetail.wardId,
        address: guestDetail.address
      })
    }
  }, [guestDetail])

  const handleGetSubZone = async () => {
    const res = await getProvinceAPI()

    if (res.data) {
      setSubZone(res.data)
    }
  }

  const handleGetDistrict = async () => {
    const res = await getDistrictAPI()

    if (res.data) {
      setDistrict(res.data)
    }
  }

  const handleGetWard = async () => {
    const res = await getWardAPI()

    if (res.data) {
      setWard(res.data)
    }
  }

  const onFinish = async (values) => {
    try {
      console.log('submit')
      const res = await updateGuestAPI({
        id: guestDetail.id,
        name: values.name,
        phone: values.phone,
        provinceId: values.subzone,
        districtId: values.district,
        wardId: values.ward,
        address: values.address
      })
      if (res) {
        toast('Cập nhật thành công thông tin người tiêu dùng', { type: 'success' })
        dispatch(getGuestList(filter))
      }

      onCloseClick()
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
  }

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true)
      },
      () => {
        setSubmittable(false)
      }
    )
  }, [watchForm])

  useEffect(() => {
    form.resetFields()
  }, [show])

  const onClickCancel = () => {
    setOnCancel(false)
    onCloseClick()
  }

  const checkClickCancel = () => {
    if (type != 'view') {
      if (!isEqual(initData, watchForm)) {
        setOnCancel(true)
      } else {
        onCloseClick()
      }
    } else {
      onCloseClick()
    }
  }

  return (
    <Modal
      size='lg'
      isOpen={show}
      toggle={() => {
        checkClickCancel()
      }}
      centered={true}
    >
      <div className='modal-content'>
        <ModalBody className='p-4 text-start'>
          <button type='button' onClick={checkClickCancel} className='btn-close position-absolute end-0 top-0 m-3' />
          <h2>{GuestVN.editGuest}</h2>
          <Form onFinish={onFinish} autoComplete='off' form={form} name='addEditGuestForm'>
            <div className='form-group pt-4'>
              <Row>
                <Col sm='6'>
                  <Label className='form-label'>
                    {GuestVN.form.name.label}
                    <a className='text-danger'>*</a>
                  </Label>
                  <Form.Item name='name' rules={[{ required: true, message: 'Vui lòng nhập Họ và tên' }]}>
                    <FormInput maxLength={225} placeholder={GuestVN.form.name.placeholder} />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>
                    {GuestVN.form.phone.label}
                    <a className='text-danger'>*</a>
                  </Label>
                  <Form.Item
                    name='phone'
                    rules={[
                      { required: true, message: 'Vui lòng nhập số điện thoại' },
                      { pattern: /^\d{10,11}$/, message: 'Số điện thoại gồm 10-11 số' }
                    ]}
                  >
                    <FormInput disabled={true} placeholder={GuestVN.form.phone.placeholder} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <Label className='form-label'>
                    {GuestVN.form.city.label}
                    <a className='text-danger'>*</a>
                  </Label>
                  <Form.Item name='subzone' rules={[{ required: true, message: 'Vui lòng chọn tỉnh/thành phố' }]}>
                    <FormSelect
                      options={subZone.map((item) => {
                        return {
                          label: item.name,
                          value: item.id
                        }
                      })}
                      placeholder={GuestVN.form.city.placeholder}
                      onChange={() => {
                        form.setFieldValue('district', undefined)
                        form.setFieldValue('ward', undefined)
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>
                    {GuestVN.form.district.label}
                    <a className='text-danger'>*</a>
                  </Label>
                  <Form.Item name='district' rules={[{ required: true, message: 'Vui lòng chọn quận/huyện' }]}>
                    <FormSelect
                      options={district
                        .filter((item) => item.parentId === watchForm?.subzone)
                        .map((item) => {
                          return {
                            label: item.name,
                            value: item.id
                          }
                        })}
                      placeholder={GuestVN.form.district.placeholder}
                      disabled={!watchForm?.subzone}
                      onChange={() => {
                        form.setFieldValue('ward', undefined)
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <Label className='form-label'>
                    {GuestVN.form.ward.label}
                    <a className='text-danger'>*</a>
                  </Label>
                  <Form.Item name='ward' rules={[{ required: true, message: 'Vui lòng chọn phường/xã' }]}>
                    <FormSelect
                      options={ward
                        .filter((item) => item.parentId === watchForm?.district)
                        .map((item) => {
                          return {
                            label: item.name,
                            value: item.id
                          }
                        })}
                      placeholder={GuestVN.form.ward.placeholder}
                      disabled={!watchForm?.district}
                    />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>
                    {GuestVN.form.address.label}
                    <a className='text-danger'>*</a>
                  </Label>
                  <Form.Item name='address' rules={[{ required: true, message: 'Vui lòng nhập địa chỉ cụ thể' }]}>
                    <FormInput maxLength={255} placeholder={GuestVN.form.address.placeholder} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className='hstack gap-2 justify-content-center mt-4'>
              <button type='button' className='btn btn-soft-secondary w-25 ' onClick={checkClickCancel}>
                Hủy
              </button>
              <button
                disabled={type !== 'view' && (!submittable || isEqual(initData, watchForm))}
                type={'button'}
                className='btn btn-primary w-25'
                onClick={debounce(() => {
                  form.submit()
                }, 500)}
              >
                {isEdit ? 'Lưu' : 'Chỉnh sửa'}
              </button>
            </div>
          </Form>
        </ModalBody>
      </div>
      <ConfirmModal
        show={onCancel}
        onConfirmClick={onClickCancel}
        onCloseClick={() => setOnCancel(false)}
        title={`Xác nhận`}
        description={`Thay đổi sẽ không được lưu. Bạn có chắc chắn muốn thoát không?`}
      />
    </Modal>
  )
}
export default AddEditGuest
