import { CommonVN, ProductVN } from 'constants/vn'
import React, { useEffect, useMemo, useState } from 'react'
import { Col, Label, Modal, ModalBody, Row } from 'reactstrap'

// Formik validation
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { filterDataHaveValue } from 'helpers/global_helper'
import { getProductList, setTypePopupProduct } from 'store/actions'
import {
  getChannelAPI,
  getPortfolioAllAPI,
  getPortfolioDetailsAPI,
  postProductAPI,
  updateProductAPI,
  checkSerialAPI
} from 'helpers/backend_helper'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { Checkbox, DatePicker, Form, Spin } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { isEqual } from 'lodash'
import dayjs from 'dayjs'
import { MAX_LENGTH_50 } from 'constants/constants'
import { FormDate } from 'components/Common/form-date/FormDate'
import ConfirmModal from 'components/Common/ConfirmModal'
import { InputNumber } from 'antd'

const AddEditProduct = ({ show, onCloseClick, selected, setPage }) => {
  const [form] = Form.useForm()
  const watchModel = Form.useWatch('model', form)
  const watchForm = Form.useWatch([], form)
  const [submittable, setSubmittable] = useState()
  const [model, setModel] = useState()
  const [channel, setChannel] = useState()
  const [initData, setInitData] = useState({})
  const [loading, setLoading] = useState(false)
  const [onCancel, setOnCancel] = useState(false)
  const [onCheckSeriral, setOnCheckSerial] = useState(false)
  const [modelType, setModelType] = useState()

  const dispatch = useDispatch()

  const selectProductsState = (state) => state.ProductReducer
  const ProductsProperties = createSelector(selectProductsState, (reducer) => ({
    type: reducer.type,
    productDetail: reducer.productDetail
  }))
  const { type, productDetail } = useSelector(ProductsProperties)

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (layout) => ({
    filter: layout.filter,
    zone: layout.zone
  }))
  const { filter, zone } = useSelector(LayoutProperties)

  const isEdit = type !== 'view'

  const data = useMemo(() => {
    if (type !== 'create') {
      return productDetail
    } else {
      return undefined
    }
  }, [type, productDetail])

  const mapsData = (data) => {
    if (data?.model?.name?.slice(-2) === '-I') {
      // form.setFieldValue('type', 'cold')
      setModelType('cold')
    } else if (data?.model?.name?.slice(-2) === '-O') {
      // form.setFieldValue('type', 'hot')
      setModelType('hot')
    } else {
      setModelType('')
    }

    return {
      serial: data.serial,
      zone: data.zoneId,
      industry: data?.industry?.name ?? undefined,
      industrySector: data?.industrySector?.name ?? undefined,
      category: data?.category?.name ?? undefined,
      model: data?.modelId,
      channel: data?.channelId,
      exportDate: dayjs(data?.exportDate),
      type: data?.type ? data?.type : undefined,
      is_noreward_accumulation: data?.isNorewardAccumulation ? data?.isNorewardAccumulation : false,
      is_noreward_activation: data?.isNorewardActivation ? data?.isNorewardActivation : false,
      is_noreward_display: data?.isNorewardDisplay ? data?.isNorewardDisplay : false
      // price: data?.price,
    }
  }

  const getDataOptions = async () => {
    try {
      const resModel = await getPortfolioAllAPI({ type: 'model' })
      const resChannel = await getChannelAPI({ sort_order: 'ASC' })
      setModel(resModel?.data)
      setChannel(resChannel?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const checkPrice = (value) => {
    if (value) {
      if (Number(value) && Number(value) < 0) {
        form.setFields([{ name: 'price', errors: ['Giá C2 phải tuân thủ các ràng buộc đã chỉ định'] }])
      } else {
        form.setFields([{ name: 'price', errors: null }])
        form.setFieldValue('price', Number(value).toFixed(2))
      }
    } else {
      form.setFields([{ name: 'price', errors: null }])
      form.setFieldValue('price', value)
    }
  }

  const onFinish = async () => {
    const values = form.getFieldsValue()
    setLoading(true)
    if (type !== 'view') {
      if (modelType) {
        if (modelType === values?.type) {
          try {
            if (type === 'edit') {
              const res = await updateProductAPI({
                id: productDetail.id,
                zoneId: values.zone,
                modelId: values?.model,
                channelId: values?.channel,
                exportDate: values?.exportDate,
                price: values?.price,
                type: values?.type ? values?.type : null,
                is_noreward_accumulation: values?.is_noreward_accumulation ? values?.is_noreward_accumulation : false,
                is_noreward_activation: values?.is_noreward_activation ? values?.is_noreward_activation : false,
                is_noreward_display: values?.is_noreward_display ? values?.is_noreward_display : false
              })
              if (res) {
                toast('Thông tin sản phẩm được cập nhật thành công', { type: 'success' })
                dispatch(getProductList(filter))
              }
            }
            if (type === 'create') {
              const res = await postProductAPI({
                serial: values.serial,
                zoneId: values.zone,
                modelId: values?.model,
                channelId: values?.channel,
                exportDate: values?.exportDate,
                price: values?.price,
                type: values?.type ? values?.type : null,
                is_noreward_accumulation: values?.is_noreward_accumulation ? values?.is_noreward_accumulation : false,
                is_noreward_activation: values?.is_noreward_activation ? values?.is_noreward_activation : false,
                is_noreward_display: values?.is_noreward_display ? values?.is_noreward_display : false
              })
              if (res) {
                toast('Sản phẩm được tạo mới thành công', { type: 'success' })
                dispatch(getProductList())
                setPage(1)
              }
            }
            onCloseClick()
          } catch (error) {
            toast(error.response.data.message, { type: 'error' })
          }
          setLoading(false)
        } else {
          toast('Model và loại sản phẩm không trùng khớp', { type: 'error' })
          setLoading(false)
        }
      } else {
        try {
          if (type === 'edit') {
            const res = await updateProductAPI({
              id: productDetail.id,
              zoneId: values.zone,
              modelId: values?.model,
              channelId: values?.channel,
              exportDate: values?.exportDate,
              price: values?.price,
              type: values?.type ? values?.type : null,
              is_noreward_accumulation: values?.is_noreward_accumulation ? values?.is_noreward_accumulation : false,
              is_noreward_activation: values?.is_noreward_activation ? values?.is_noreward_activation : false,
              is_noreward_display: values?.is_noreward_display ? values?.is_noreward_display : false
            })
            if (res) {
              toast('Thông tin sản phẩm được cập nhật thành công', { type: 'success' })
              dispatch(getProductList(filter))
            }
          }
          if (type === 'create') {
            const res = await postProductAPI({
              serial: values.serial,
              zoneId: values.zone,
              modelId: values?.model,
              channelId: values?.channel,
              exportDate: values?.exportDate,
              price: values?.price,
              type: values?.type ? values?.type : null,
              is_noreward_accumulation: values?.is_noreward_accumulation ? values?.is_noreward_accumulation : false,
              is_noreward_activation: values?.is_noreward_activation ? values?.is_noreward_activation : false,
              is_noreward_display: values?.is_noreward_display ? values?.is_noreward_display : false
            })
            if (res) {
              toast('Sản phẩm được tạo mới thành công', { type: 'success' })
              dispatch(getProductList())
              setPage(1)
            }
          }
          onCloseClick()
        } catch (error) {
          toast(error.response.data.message, { type: 'error' })
        }
        setLoading(false)
      }
    } else {
      dispatch(setTypePopupProduct('edit'))
      setLoading(false)
    }
    setOnCheckSerial(false)
    setLoading(false)
  }

  const handeCheckSerial = async (value) => {
    if (type === 'create') {
      const res = await checkSerialAPI({ serial: value.serial })

      if (res.data.exists) {
        setOnCheckSerial(true)
      } else {
        onFinish()
      }
    } else {
      onFinish()
    }
  }

  const getModelDetail = async () => {
    const res = await getPortfolioDetailsAPI({ id: watchModel, type: 'model' })
    form.setFieldValue('industry', res.data.industry.name)
    form.setFieldValue('industrySector', res.data.industrySector.name)
    form.setFieldValue('category', res.data.category.name)
  }

  useEffect(() => {
    if (watchModel) {
      getModelDetail()
    }
  }, [watchModel])

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true)
      },
      () => {
        setSubmittable(false)
      }
    )
  }, [watchForm])

  useEffect(() => {
    if (show) {
      getDataOptions()
    }
    form.resetFields()
  }, [show])

  useEffect(() => {
    if (data) {
      form.setFieldsValue(mapsData(data))
      setInitData(mapsData(data))
    } else {
      form.resetFields()
    }
  }, [data])

  const onClickCancel = () => {
    setOnCancel(false)
    onCloseClick()
  }

  const checkClickCancel = () => {
    if (type != 'view') {
      if (type == 'create') {
        if (form.isFieldsTouched()) {
          setOnCancel(true)
        } else {
          onCloseClick()
        }
      } else {
        if (!isEqual(initData, watchForm)) {
          setOnCancel(true)
        } else {
          onCloseClick()
        }
      }
    } else {
      onCloseClick()
    }
  }

  return (
    <Modal
      size='lg'
      isOpen={show}
      toggle={() => {
        checkClickCancel()
      }}
      centered={true}
    >
      <div className='modal-content'>
        <ModalBody className='p-4 text-start'>
          <Spin spinning={loading}>
            <button type='button' onClick={checkClickCancel} className='btn-close position-absolute end-0 top-0' />
            <h2>
              {type === 'create'
                ? ProductVN.addProduct
                : type === 'edit'
                ? ProductVN.editProduct
                : ProductVN.viewProduct}
            </h2>
            <Form onFinish={handeCheckSerial} autoComplete='off' form={form} name='addEditProductForm'>
              <div className='form-group pt-4'>
                <Row>
                  <Col sm='6'>
                    <Label className='form-label'>
                      {ProductVN.form.serial.label}
                      <a className='text-danger'>*</a>
                    </Label>
                    <Form.Item
                      name='serial'
                      rules={[
                        { required: true, message: 'Vui lòng nhập Serial' },
                        { pattern: /^[a-zA-Z0-9]+$/, message: 'Serial chỉ bao gồm chữ và số' }
                      ]}
                    >
                      <FormInput
                        placeholder={ProductVN.form.serial.placeholder}
                        disabled={type !== 'create' || productDetail?.extTransId}
                        maxLength={MAX_LENGTH_50}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm='6'>
                    <Label className='form-label'>
                      {ProductVN.form.zone.label}
                      <a className='text-danger'>*</a>
                    </Label>
                    <Form.Item name='zone' rules={[{ required: true, message: 'Vui lòng chọn Miền' }]}>
                      <FormSelect
                        placeholder={ProductVN.form.zone.placeholder}
                        options={zone?.map((item) => ({
                          value: item.id,
                          label: item.name
                        }))}
                        disabled={!isEdit || productDetail?.extTransId}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col sm='6'>
                    <Label className='form-label'>{ProductVN.form.industry.label}</Label>
                    <Form.Item name='industry'>
                      <FormInput placeholder={''} disabled />
                    </Form.Item>
                  </Col>
                  <Col sm='6'>
                    <Label className='form-label'>{ProductVN.form.industrySector.label}</Label>
                    <Form.Item name='industrySector'>
                      <FormInput placeholder={''} disabled />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col sm='6'>
                    <Label className='form-label'>{ProductVN.form.category.label}</Label>
                    <Form.Item name='category'>
                      <FormInput placeholder={''} disabled />
                    </Form.Item>
                  </Col>
                  <Col sm='6'>
                    <Label className='form-label'>
                      {'Model'}
                      <a className='text-danger'>*</a>
                    </Label>
                    <Form.Item name='model' rules={[{ required: true, message: 'Vui lòng chọn model' }]}>
                      <FormSelect
                        // listHeight={450}
                        placeholder={ProductVN.form.model.placeholder}
                        options={model?.map((item) => ({
                          value: item?.id,
                          label: item?.name,
                          price: item?.price
                        }))}
                        onChange={(e, item) => {
                          form.setFieldValue('price', item?.price)

                          if (item?.label.slice(-2) === '-I') {
                            form.setFieldValue('type', 'cold')
                            setModelType('cold')
                          } else if (item?.label.slice(-2) === '-O') {
                            form.setFieldValue('type', 'hot')
                            setModelType('hot')
                          } else {
                            form.setFieldValue('type', undefined)
                          }
                        }}
                        disabled={!isEdit || productDetail?.extTransId}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col sm='6'>
                    <Label className='form-label'>
                      {ProductVN.form.channel.label}
                      <a className='text-danger'>*</a>
                    </Label>
                    <Form.Item name='channel' rules={[{ required: true, message: 'Vui lòng chọn kênh kinh doanh' }]}>
                      <FormSelect
                        placeholder={ProductVN.form.channel.placeholder}
                        options={channel?.map((item) => {
                          const name = item.name
                          return {
                            value: item.id,
                            label: name.charAt(0).toUpperCase() + name.slice(1)
                          }
                        })}
                        disabled={!isEdit || productDetail?.extTransId}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm='6'>
                    <Label className='form-label'>
                      {ProductVN.form.exportDate.label}
                      <a className='text-danger'>*</a>
                    </Label>
                    <Form.Item name='exportDate' rules={[{ required: true, message: 'Vui lòng chọn ngày xuất kho' }]}>
                      <FormDate
                        disabled={!isEdit || productDetail?.extTransId}
                        disabledDate={(current) => dayjs().isBefore(current, 'day')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col sm='6'>
                    <Label className='form-label'>{ProductVN.form.type.label}</Label>
                    <Form.Item name='type'>
                      <FormSelect
                        placeholder={ProductVN.form.type.placeholder}
                        options={[
                          { value: 'hot', label: 'Cục nóng' },
                          { value: 'cold', label: 'Cục lạnh' }
                        ]}
                        disabled={!isEdit || productDetail?.extTransId}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm='6'>
                    <Label className='form-label'>{'Chặn thưởng'}</Label>
                    <Row>
                      <Col sm='4'>
                        <Form.Item name='is_noreward_accumulation' valuePropName='checked'>
                          <Checkbox className='fw-normal' disabled={!isEdit}>
                            {'Tích luỹ'}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col sm='4'>
                        <Form.Item name='is_noreward_activation' valuePropName='checked'>
                          <Checkbox disabled={!isEdit} className='fw-normal'>
                            {'Kích hoạt'}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col sm='4'>
                        <Form.Item name='is_noreward_display' valuePropName='checked'>
                          <Checkbox disabled={!isEdit} className='fw-normal'>
                            {'Trưng bày'}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className='hstack gap-2 justify-content-center mt-4'>
                <button type='button' className='btn btn-soft-secondary w-25 ' onClick={checkClickCancel}>
                  Hủy
                </button>
                {type == 'view' ? (
                  <button
                    disabled={productDetail?.status != 'active' || productDetail?.extTransId}
                    type={'submit'}
                    className='btn btn-primary w-25'
                    // onClick={() => {
                    //     console.log("hoang")
                    //     if (!isEdit) {
                    //         dispatch(setTypePopup("edit"))
                    //     }
                    // }}
                  >
                    {'Chỉnh sửa'}
                  </button>
                ) : (
                  <button
                    disabled={type !== 'view' && (!submittable || isEqual(initData, watchForm))}
                    type={'submit'}
                    className='btn btn-primary w-25'
                    // onClick={() => {
                    //     console.log("hoang")
                    //     if (!isEdit) {
                    //         dispatch(setTypePopup("edit"))
                    //     }
                    // }}
                  >
                    {isEdit ? 'Lưu' : 'Chỉnh sửa'}
                  </button>
                )}
              </div>
            </Form>
          </Spin>
        </ModalBody>
        <ConfirmModal
          show={onCancel}
          onConfirmClick={onClickCancel}
          onCloseClick={() => setOnCancel(false)}
          title={`Xác nhận`}
          description={`Thay đổi sẽ không được lưu. Bạn có chắc chắn muốn thoát không?`}
        />
        <ConfirmModal
          show={onCheckSeriral}
          onConfirmClick={onFinish}
          onCloseClick={() => setOnCheckSerial(false)}
          title={`Xác nhận trùng serial`}
          description={`Serial sản phẩm đã tồn tại trên hệ thống.`}
        />
      </div>
    </Modal>
  )
}
export default AddEditProduct
