import { ActivationVN } from 'constants/vn'
import React, { useEffect, useMemo, useState } from 'react'
import { Col, Label, Modal, ModalBody, Row, UncontrolledTooltip } from 'reactstrap'

// Formik validation
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { filterDataHaveValue, formateDate } from 'helpers/global_helper'
import { getActivationList, setTypePopupActivation } from 'store/actions'
import { getModelBySerialAPI, updateActivationAPI } from 'helpers/backend_helper'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { Form } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { debounce, isEqual } from 'lodash'
import { MAX_LENGTH_50 } from 'constants/constants'
import { StatusActivation } from '../ManageActivationCol'
import ConfirmModal from 'components/Common/ConfirmModal'

const AddEditActivation = ({ show, onCloseClick, selected }) => {
  const [form] = Form.useForm()
  const watchModel = Form.useWatch('model', form)
  const watchForm = Form.useWatch([], form)
  const [submittable, setSubmittable] = useState()
  const [model, setModel] = useState()
  const [initData, setInitData] = useState({})
  const [onCancel, setOnCancel] = useState(false)

  const dispatch = useDispatch()

  const selectActivationsState = (state) => state.ActivationReducer
  const ActivationsProperties = createSelector(selectActivationsState, (reducer) => ({
    type: reducer.type,
    activationDetail: reducer.activationDetail
  }))
  const { type, activationDetail } = useSelector(ActivationsProperties)

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (layout) => ({
    filter: layout.filter,
    zone: layout.zone
  }))
  const { filter, zone } = useSelector(LayoutProperties)
  const [lat, setLat] = useState()
  const [long, setLong] = useState()
  const [link, setLink] = useState()

  const isEdit = type !== 'view'

  const data = useMemo(() => {
    return activationDetail
  }, [activationDetail])

  const mapsData = (data) => {
    setLat(parseFloat(data?.userReward?.latitude ? data?.userReward?.latitude : 0).toFixed(6))
    setLong(parseFloat(data?.userReward?.longitude ? data?.userReward?.longitude : 0).toFixed(6))
    setLink(
      `https://www.google.com/maps?q=${parseFloat(data?.userReward?.latitude ? data?.userReward?.latitude : 0).toFixed(
        6
      )},${parseFloat(data?.userReward?.longitude ? data?.userReward?.longitude : 0).toFixed(6)}`
    )

    return {
      serial: data?.product?.serial,
      model: data?.product?.modelId,
      category: data?.product?.category?.name ?? undefined,
      industrySector: data?.product?.industrySector?.name ?? undefined,
      industry: data?.product?.industry?.name ?? undefined,
      zone: data?.product?.zone?.name,
      channel: data?.product?.channel?.name,
      exportDate: formateDate(data?.product?.exportDate),

      //shop info
      accountType: data?.userReward?.user?.client === 'user' ? 'Cửa hàng' : 'Kĩ thuật viên',
      accountName: data?.userReward?.user?.accountName,
      phone: data?.userReward?.user?.phone,
      taxCode: data?.userReward?.user?.taxCode,
      province: data?.userReward?.user?.province?.name,
      PIC: data?.userReward?.user?.client === 'user' ? data?.userReward?.user?.shopName : data?.userReward?.user?.name,

      //client info
      name: data?.customer?.name,
      phoneClient: data?.customer?.phone,
      addressClient: data?.customer?.address,
      provinceClient: data?.customer?.ward?.parent?.parent?.name,

      activationCode: data?.userReward?.productActivation ? data?.userReward?.productActivation?.code : undefined,
      activationDate: formateDate(data?.userReward?.createdAt),
      secondProduct: data?.secondProduct?.serial,
      status: data?.userReward?.status,
      link: `https://www.google.com/maps?q=${parseFloat(
        data?.userReward?.latitude ? data?.userReward?.latitude : 0
      ).toFixed(6)},${parseFloat(data?.userReward?.longitude ? data?.userReward?.longitude : 0).toFixed(6)}`,
      latitude: parseFloat(data?.userReward?.latitude ? data?.userReward?.latitude : 0).toFixed(6),
      longitude: parseFloat(data?.userReward?.longitude ? data?.userReward?.longitude : 0).toFixed(6),
      longlat: `(${parseFloat(data?.userReward?.latitude ? data?.userReward?.latitude : 0).toFixed(6)}, ${parseFloat(
        data?.userReward?.longitude ? data?.userReward?.longitude : 0
      ).toFixed(6)})`
    }
  }

  const onFinish = async (values) => {
    if (type !== 'view') {
      try {
        console.log('submit')
        const res = await updateActivationAPI({
          id: activationDetail.id,
          modelId: values?.model,
          userId: activationDetail?.userReward?.userId
        })
        if (res) {
          toast(res.message, { type: 'success' })
          dispatch(getActivationList(filter))
        }

        onCloseClick()
      } catch (error) {
        console.log(error)
        toast(error.response.data.message, { type: 'error' })
      }
    } else {
      dispatch(setTypePopupActivation('edit'))
    }
  }

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true)
      },
      () => {
        setSubmittable(false)
      }
    )
  }, [watchForm])

  useEffect(() => {
    form.resetFields()
  }, [show])

  useEffect(() => {
    if (data) {
      form.setFieldsValue(mapsData(data))
      setInitData(mapsData(data))
    } else {
      form.resetFields()
    }
  }, [data])
  const getModelBySerial = async () => {
    const res = await getModelBySerialAPI(data?.product?.serial)
    console.log(res)
    setModel(res?.data)
  }

  useEffect(() => {
    getModelBySerial()
  }, [data])

  const onClickCancel = () => {
    setOnCancel(false)
    onCloseClick()
  }

  const checkClickCancel = () => {
    if (type != 'view') {
      if (type == 'create') {
        if (form.isFieldsTouched()) {
          setOnCancel(true)
        } else {
          onCloseClick()
        }
      } else {
        if (!isEqual(initData, watchForm)) {
          setOnCancel(true)
        } else {
          onCloseClick()
        }
      }
    } else {
      onCloseClick()
    }
  }

  return (
    <Modal
      size='lg'
      isOpen={show}
      toggle={() => {
        checkClickCancel()
      }}
      centered={true}
    >
      <div className='modal-content'>
        <ModalBody className='p-4 text-start'>
          <button type='button' onClick={checkClickCancel} className='btn-close position-absolute end-0 top-0 m-3' />
          <h2>{type === 'edit' ? ActivationVN.editActivation : ActivationVN.viewActivation}</h2>
          <Form onFinish={onFinish} autoComplete='off' form={form} name='addEditActivationForm'>
            <div className='form-group pt-4'>
              <h5>{ActivationVN.form.productInfo}</h5>

              <Row>
                <Col sm='6'>
                  <Label className='form-label'>
                    {ActivationVN.form.serial}
                    <a className='text-danger'>*</a>
                  </Label>
                  <Form.Item name='serial' rules={[{ required: true, message: 'Vui lòng nhập Serial' }]}>
                    <FormInput disabled={type !== 'create'} maxLength={MAX_LENGTH_50} />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>
                    {ActivationVN.form.model}
                    <a className='text-danger'>*</a>
                  </Label>
                  <Form.Item name='model' rules={[{ required: true, message: 'Vui lòng chọn model' }]}>
                    <FormSelect
                      placeholder={ActivationVN.form.model.placeholder}
                      options={model?.map((item) => ({
                        value: item.id,
                        label: item.name,
                        code: item.code
                      }))}
                      disabled={!isEdit}
                      // onChange={value => {
                      //     console.log('value', value)
                      // }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <Label className='form-label'>{ActivationVN.form.category}</Label>
                  <Form.Item name='category'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>{ActivationVN.form.industrySector}</Label>
                  <Form.Item name='industrySector'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <Label className='form-label'>{ActivationVN.form.industry}</Label>
                  <Form.Item name='industry'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>{ActivationVN.form.zone}</Label>
                  <Form.Item name='zone'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <Label className='form-label'>{ActivationVN.form.channel}</Label>
                  <Form.Item name='channel'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>{ActivationVN.form.exportDate}</Label>
                  <Form.Item name='exportDate'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
              </Row>
              <h5 className='mt-4'>{ActivationVN.form.shopInfo}</h5>
              <Row>
                <Col sm='6'>
                  <Label className='form-label'>{ActivationVN.form.accountType}</Label>
                  <Form.Item name='accountType'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>{ActivationVN.form.accountName}</Label>
                  <Form.Item name='accountName'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <Label className='form-label'>{ActivationVN.form.phone}</Label>
                  <Form.Item name='phone'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>{ActivationVN.form.province}</Label>
                  <Form.Item name='province'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
              </Row>
              {data?.userReward?.user?.client === 'user' && (
                <Row>
                  <Col sm='6'>
                    <Label className='form-label'>{ActivationVN.form.taxCode}</Label>
                    <Form.Item name='taxCode'>
                      <FormInput disabled />
                    </Form.Item>
                  </Col>
                  <Col sm='6'>
                    <Label className='form-label'>{ActivationVN.form.PIC}</Label>
                    <Form.Item name='PIC'>
                      <FormInput disabled />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <h5 className='mt-4'>{ActivationVN.form.consumersInfo}</h5>

              <Row>
                <Col sm='6'>
                  <Label className='form-label'>{ActivationVN.form.name}</Label>
                  <Form.Item name='name'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>{ActivationVN.form.phone}</Label>
                  <Form.Item name='phoneClient'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <Label className='form-label'>{ActivationVN.form.address}</Label>
                  <Form.Item name='addressClient'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>{ActivationVN.form.province}</Label>
                  <Form.Item name='provinceClient'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
              </Row>
              <h5 className='mt-4'>{ActivationVN.form.activationInfo}</h5>

              <Row>
                <Col sm='6'>
                  <Label className='form-label'>{ActivationVN.form.activationCode}</Label>
                  <Form.Item name='activationCode'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>{ActivationVN.form.activationDate}</Label>
                  <Form.Item name='activationDate'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>{ActivationVN.form.serialRelatetTo}</Label>
                  <Form.Item name='secondProduct'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
                <Col sm='3'>
                  <Label className='form-label'>{ActivationVN.form.status}</Label>
                  <Form.Item name='status'>
                    <StatusActivation />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>{'Vị trí'}</Label>
                  <Form.Item name='longlat'>
                    {/* <FormInput disabled /> */}
                    <a href={`https://www.google.com/maps?q=${lat},${long}`} target='_blank' rel='noopener noreferrer'>
                      (`(${lat}, ${long})`)
                    </a>
                  </Form.Item>
                </Col>
                {activationDetail?.userReward?.isDeleted && (
                  <>
                    <Col sm='3'>
                      <Label className='form-label'> </Label>
                      <i
                        className='mdi mdi-recycle-variant'
                        style={{ color: 'red', fontSize: '50px' }}
                        id={`viewtooltip-bravo-${activationDetail.id}`}
                      />
                      <UncontrolledTooltip
                        trigger='hover'
                        placement='top'
                        target={`viewtooltip-bravo-${activationDetail.id}`}
                      >
                        Sản phẩm bị thu hồi.
                      </UncontrolledTooltip>
                    </Col>
                  </>
                )}
              </Row>
            </div>
            <div className='hstack gap-2 justify-content-center mt-4'>
              <button type='button' className='btn btn-soft-secondary w-25 ' onClick={checkClickCancel}>
                Hủy
              </button>
              {/* <button
                                disabled={type !== "view" && (!submittable || isEqual(initData, watchForm))}
                                type={"button"}
                                className="btn btn-primary w-25"
                                onClick={debounce(() => {
                                    form.submit()
                                }, 500)}
                            >
                                {isEdit ? "Lưu" : "Chỉnh sửa"}
                            </button> */}
            </div>
          </Form>
        </ModalBody>
      </div>
      <ConfirmModal
        show={onCancel}
        onConfirmClick={onClickCancel}
        onCloseClick={() => setOnCancel(false)}
        title={`Xác nhận`}
        description={`Thay đổi sẽ không được lưu. Bạn có chắc chắn muốn thoát không?`}
      />
    </Modal>
  )
}
export default AddEditActivation
