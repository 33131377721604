import { PortfolioVN } from 'constants/vn'
import React, { useEffect, useMemo, useState } from 'react'
import { Col, Label, Modal, ModalBody, Row } from 'reactstrap'
import { MAX_LENGTH_NAME_PORTFOLIO, TypePortfolioOptions } from 'constants/constants'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { filterDataHaveValue, getParentId } from 'helpers/global_helper'
import { getPortfolioList, loginUser, setTypePopup } from 'store/actions'
import { getPortfolioAllAPI, postPortfolioAPI, updatePortfolioAPI } from 'helpers/backend_helper'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { Checkbox, Form, Switch } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { isEqual, size } from 'lodash'
import ConfirmModal from 'components/Common/ConfirmModal'

const SelectedIndustry = ({ show, onCloseClick, selected, setPage, loginValues, props }) => {
  const dispatch = useDispatch()

  const selectPortfoliosState = (state) => state.PortfolioReducer
  const PortfoliosProperties = createSelector(selectPortfoliosState, (reducer) => ({
    type: reducer.type,
    portfolioDetail: reducer.portfolioDetail
  }))
  const { type, portfolioDetail } = useSelector(PortfoliosProperties)

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (layout) => ({
    filter: layout.filter
  }))
  const { filter } = useSelector(LayoutProperties)

  const isEdit = type !== 'view'
  const [form] = Form.useForm()
  const watchType = Form.useWatch('type', form)
  const watchForm = Form.useWatch([], form)
  const [submittable, setSubmittable] = useState()
  const [industry, setIndustry] = useState()
  const [industry1, setIndustry1] = useState([
    {
      label: 'Điện lạnh',
      value: 1
    },
    {
      label: 'Gia dụng',
      value: 2
    }
  ])
  const [industrySector, setIndustrysector] = useState()
  const [category, setCategory] = useState()
  const [initData, setInitData] = useState({})
  const [onCancel, setOnCancel] = useState(false)

  const data = useMemo(() => {
    if (type !== 'create') {
      return portfolioDetail
    } else {
      return undefined
    }
  }, [type, portfolioDetail])

  const mapsData = (data) => {
    return filterDataHaveValue({
      type: data.type,
      name: data.name,
      industry: data?.industryId ?? undefined,
      industrySector: data?.industrySectorId ?? undefined,
      category: data?.categoryId ?? undefined,
      isAccumulation: data?.isAccumulation ?? undefined,
      isActivated: data?.isActivated ?? undefined,
      requireActivationCode: data?.requireActivationCode ?? undefined
    })
  }
  const getDataOptions = async () => {
    try {
      const resIndustry = await getPortfolioAllAPI({ type: 'industry' })
      const resIndustrysector = await getPortfolioAllAPI({ type: 'industrySector' })
      const resCategory = await getPortfolioAllAPI({ type: 'category' })
      setIndustry(resIndustry?.data)
      setIndustrysector(resIndustrysector?.data)
      setCategory(resCategory?.data)
    } catch (error) {
      console.log(error)
      // toast(error.response.data.message, { type: "error" })
    }
  }

  useEffect(() => {
    if (show) {
      getDataOptions()
    }
  }, [show])

  useEffect(() => {
    if (data) {
      form.setFieldsValue(mapsData(data))
      setInitData(mapsData(data))
    } else {
      form.resetFields()
    }
  }, [data])

  useEffect(() => {
    form.resetFields()
  }, [show])

  const onFinish = async (values) => {
    dispatch(loginUser({ ...loginValues, industry_id: values?.industry }, props.router.navigate))
  }

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true)
      },
      () => {
        setSubmittable(false)
      }
    )
  }, [watchForm])

  const onClickCancel = () => {
    setOnCancel(false)
    onCloseClick()
  }

  const checkClickCancel = () => {
    if (type != 'view') {
      if (type == 'create') {
        if (form.isFieldsTouched()) {
          setOnCancel(true)
        } else {
          onCloseClick()
        }
      } else {
        if (!isEqual(initData, watchForm)) {
          setOnCancel(true)
        } else {
          onCloseClick()
        }
      }
    } else {
      onCloseClick()
    }
  }

  return (
    <Modal
      size=''
      isOpen={show}
      toggle={() => {
        checkClickCancel()
      }}
      centered={false}
    >
      <div className='modal-content'>
        <ModalBody className=' text-start'>
          <button type='button' onClick={checkClickCancel} className='btn-close position-absolute end-0 top-0 m-3' />
          <h2>{'Chọn ngành'}</h2>
          <Form onFinish={onFinish} autoComplete='off' form={form} name='addEditPortfolioForm'>
            <div className='col-sm-8 mx-auto'>
              <div className='col-sm-8 mx-auto'>
                <p>{'Bạn muốn truy cập vào đâu'}</p>
              </div>
              <Form.Item
                name='industry'
                rules={[{ required: true, message: 'Vui lòng chọn ngành hàng để tiếp tục truy cập' }]}
              >
                <FormSelect options={industry1} placeholder='Chọn Ngành' disabled={!isEdit} />
              </Form.Item>
            </div>
            <div className='hstack gap-2 justify-content-center mt-4'>
              <button type={'submit'} className='btn btn-primary w-25'>
                Chọn
              </button>
            </div>
          </Form>
        </ModalBody>
        <ConfirmModal
          show={onCancel}
          onConfirmClick={onClickCancel}
          onCloseClick={() => setOnCancel(false)}
          title={`Xác nhận`}
          description={`Thay đổi sẽ không được lưu. Bạn có chắc chắn muốn thoát không?`}
        />
      </div>
    </Modal>
  )
}
export default SelectedIndustry
