import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Alert, Card, CardBody, Col, Container, Input, Label, Row, UncontrolledTooltip } from 'reactstrap'

//redux
import { useDispatch } from 'react-redux'
import withRouter from 'components/Common/withRouter'

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb'
import { ManageContentVN, ManageNotificaionVN } from 'constants/vn'

// styles
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { getContentDetail, getNotificationDetail } from 'store/actions'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import {
  lockAccountAPI,
  putRequestAPI,
  putContentDetailAPI,
  resetPasswordAPI,
  postContentAPI,
  getProgramListAPI,
  getProgramDetailsAPI,
  getRankAllAPI,
  postImage,
  patchStatusContentAPI,
  postNotificationAPI,
  updateNotificationAPI,
  getUserAccountsAPI
  // getUserByWardAPI
} from 'helpers/backend_helper'
import { checkFileSizeValid, checkIsLocked, formateDate } from 'helpers/global_helper'
import { toast } from 'react-toastify'
import { MAX_LENGTH_NAME, typeAccountProgramOptions, TypeContentOptions, TypeProgramOptions } from 'constants/constants'

import { concat, debounce, isEqual } from 'lodash'
import { Form, Image, Upload, Button, Spin, Tooltip, Radio, TimePicker } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { FormInput } from 'components/Common/form-input/FormInput'

import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'
import dayjs from 'dayjs'

import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import ConfirmModal from 'components/Common/ConfirmModal'
import moment from 'moment'
import EditorCustom from 'components/Common/EditorCustom/EditorCustom'
import { FormDate } from 'components/Common/form-date/FormDate'
import TextArea from 'antd/es/input/TextArea'

const NotificationDetail = (props) => {
  //meta title
  document.title = 'Notification Detail | HPG'

  const isEdit = props.router.location.pathname.includes('edit') || props.router.location.pathname.includes('create')
  const isCreate = props.router.location.pathname.includes('create')
  const params = props.router.params
  const navigate = props.router.navigate
  const uploadRef = useRef()

  const [form] = Form.useForm()
  const watchForm = Form.useWatch([], form)
  const [programOptions, setProgramOptions] = useState([])
  const [rank, setRank] = useState([])
  const [userAccountOptions, setUserAccountOptions] = useState([])

  const dispatch = useDispatch()

  const selectNotificationDetailState = (state) => state.NotificationReducer
  const NotificationDetailProperties = createSelector(selectNotificationDetailState, (content) => ({
    notificationDetail: content.notificationDetail
  }))

  const { notificationDetail } = useSelector(NotificationDetailProperties)

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    province: app.province,
    district: app.district,
    ward: app.ward,
    zone: app.zone,
    subzone: app.subzone
  }))
  const { province, district, ward, zone, subzone } = useSelector(LayoutProperties)

  const [showReject, setShowReject] = useState(false)
  const [submittable, setSubmittable] = useState(false)
  const [initData, setInitData] = useState()
  const [showApprove, setShowApprove] = useState(false)
  const [editorState, setEditorState] = useState('')
  const [imageUrl, setImageUrl] = useState()
  const [programDetail, setProgramDetail] = useState({})
  const [onCancel, setOnCancel] = useState(false)
  const [showPause, setShowPause] = useState(false)
  const [showPlay, setShowPlay] = useState(false)
  const [loading, setLoading] = useState(true)
  const [listUser, setListUser] = useState([])

  const closePopup = () => {
    setShowReject(false)
    setShowApprove(false)
    setShowPause(false)
    setShowPlay(false)
  }

  const onUpdateRequest = debounce(async (status, note) => {
    try {
      const res = await patchStatusContentAPI({
        id: parseInt(params.id, 10),
        status,
        node: note
      })
      if (res) {
        toast(status === 'rejected' ? 'Từ chối bài viết thành công' : ' Phê duyệt bài viết thành công', {
          type: 'success'
        })
        dispatch(getContentDetail(params.id))
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
    closePopup()
  }, 500)

  const mapsData = (data) => {
    setEditorState(data?.body)
    const sendTime = moment(data?.sendDate).format('HH:mm:ss').toString()

    return {
      title: data?.title,
      applySettingSending: true,
      sendDate: moment(data?.sendDate),
      sendTime: dayjs(sendTime, 'HH:mm:ss'),
      zone: data?.zones.map((e) => e.id),
      subzone: data?.subZones.map((e) => e.id),
      province: data?.provinces.map((e) => e.id),
      district: data?.districts.map((e) => e.id),
      wardIds: data?.wards.map((e) => e.id),
      userClients: data?.userClients,
      receivers: data?.receivers.map((e) => e.id),
      body: data?.body
    }
  }

  const onFinish = async (values) => {
    // debugger
    try {
      const sendDate = values?.sendDate
        ? moment(new Date(values?.sendDate)).format('YYYY-MM-DD')
        : moment(new Date()).format('YYYY-MM-DD')
      const sendTime = values?.sendTime
        ? moment(new Date(values?.sendTime)).format('HH:mm:ss')
        : moment(new Date()).format('HH:mm:ss')

      const sendDateTime = new Date(Date.parse(sendDate.concat(' ', sendTime))).toISOString()
      console.log(sendDateTime, 'sendDateTime')

      if (isCreate) {
        const res = await postNotificationAPI({
          ...values,
          title: values.title,
          body: values?.body,
          send_date: sendDateTime,
          receivers: values?.receivers
        })
        if (res) {
          toast('Thêm mới thông báo thành công', { type: 'success' })
          form.resetFields()

          navigate('/manage-notification')
        }
      } else {
        const res = await updateNotificationAPI({
          ...values,
          title: values.title,
          body: values?.body,
          // send_date: values.send_date,
          send_date: sendDateTime,
          receivers: values?.receivers,
          id: parseInt(params.id, 10)
        })
        if (res) {
          toast('Cập nhật thông báo thành công', { type: 'success' })
          form.resetFields()

          // dispatch(getContentDetail(params.id))
          navigate('/manage-notification')
        }
      }
      // navigate("/manage-content")
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const beforeUpload = async (file) => {
    return new Promise((resolve, reject) => {
      if (
        file.type == 'image/png' ||
        file.type == 'image/jpeg' ||
        file.type == 'image/jpg' ||
        file.type == 'image/jpe' ||
        file.type == 'image/hiec' ||
        file.type == 'image/heif'
      ) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.addEventListener('load', (event) => {
          const _loadedImageUrl = event.target.result
          const image = document.createElement('img')
          image.src = _loadedImageUrl
          image.addEventListener('load', () => {
            const { width, height } = image
            // set image width and height to your state here
            if (width < 670 || height < 360) {
              toast('Kích thước ảnh tối thiểu: 670x360 pixels.', {
                type: 'error'
              })
              reject()
            } else if (width / height < 1.7 || width / height > 1.9) {
              toast('Tỉ lệ chiều dài / chiều rộng tiêu chuẩn từ 1.7 đến 1.9.', {
                type: 'error'
              })
              reject()
            } else if (file.size / 1024 >= 5 * 1024) {
              toast('Dung lượng ảnh không được vượt quá 5MB', {
                type: 'error'
              })
              reject()
            } else {
              resolve(file)
            }
          })
        })
      } else {
        toast('Vui lòng chọn file ảnh có định dạng jpg, jpeg, png, jpe, hiec, heif', {
          type: 'error'
        })
        reject()
      }
    })
  }

  const hanleFileChange = ({ file, onSuccess, onError }) => {
    console.log('file', file)
    const fileValid = checkFileSizeValid(file)
    if (fileValid) {
      handleAcceptedFiles(file, onSuccess, onError)
    } else {
      toast('Ảnh vượt quá dung lượng cho phép (5MB). Vui lòng chọn ảnh khác', {
        type: 'error'
      })
    }
  }

  const handleAcceptedFiles = async (file, onSuccess, onError) => {
    let formData = new FormData()
    formData.append('file', file)

    try {
      const res = await postImage(formData)
      const nameFile = handleGetNameFile(res.data.data?.path)
      if (res) {
        setImageUrl({
          uid: '-1',
          name: nameFile,
          status: 'done',
          url: res.data.data?.pre + res.data.data?.path,
          thumbUrl: res.data.data?.pre + res.data.data?.path
        })
        toast(res.message, { type: 'success' })
        onSuccess()
      }
    } catch (error) {
      onError()
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleGetNameFile = (path) => {
    const pathSplit = path.split('/')
    return pathSplit[pathSplit.length - 1]
  }

  useEffect(() => {
    getUserAccountOptions()
  }, [watchForm?.wardIds, watchForm?.userClients])

  useEffect(() => {
    if (notificationDetail) {
      setInitData(JSON.parse(JSON.stringify(mapsData(notificationDetail))))
      form.setFieldsValue(mapsData(notificationDetail))
      setLoading(false)
    } else {
      if (isCreate) {
        setLoading(false)
      }
    }
  }, [notificationDetail])

  useEffect(() => {
    console.log('params', params)
    if (params.id) {
      dispatch(getNotificationDetail(params.id))
    }
  }, [dispatch])

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true)
      },
      () => {
        setSubmittable(false)
      }
    )
  }, [watchForm])

  const rankKTVOptions = useMemo(
    () =>
      [{ label: 'Tất cả', value: 'all' }].concat(
        rank?.filter((r) => r.client === 'technician')?.map((e) => ({ label: e.name, value: e.id }))
      ),
    [rank]
  )

  const rankShopOptions = useMemo(
    () =>
      [{ label: 'Tất cả', value: 'all' }].concat(
        rank?.filter((r) => r.client === 'user')?.map((e) => ({ label: e.name, value: e.id }))
      ),
    [rank]
  )

  const zoneOptions = useMemo(
    () => [{ label: 'Tất cả', value: 'all' }].concat(zone?.map((e) => ({ label: e.name, value: e.id }))),
    [zone]
  )

  const subzoneOptions = useMemo(() => {
    const listFilter = subzone
      ?.filter((e) => watchForm?.zone?.includes(e.zoneId))
      ?.map((e) => ({ label: e.name, value: e.id }))
    return listFilter?.length > 0 ? [{ label: 'Tất cả', value: 'all' }].concat(listFilter) : []
  }, [subzone, watchForm])

  const provinceOptions = useMemo(() => {
    const listFilter = province
      ?.filter((e) => watchForm?.subzone?.includes(e.subZoneId))
      ?.map((e) => ({ label: e.name, value: e.id }))
    return listFilter.length > 0 ? [{ label: 'Tất cả', value: 'all' }].concat(listFilter) : []
  }, [province, watchForm])

  const districtOptions = useMemo(() => {
    const listFilter = district
      ?.filter((e) => watchForm?.province?.includes(e.parentId))
      ?.map((e) => ({ label: e.name, value: e.id }))
    return listFilter.length > 0 ? [{ label: 'Tất cả', value: 'all' }].concat(listFilter) : []
  }, [district, watchForm])

  const wardOptions = useMemo(() => {
    const listFilter = ward
      ?.filter((e) => watchForm?.district?.includes(e.parentId))
      ?.map((e) => ({ label: e.name, value: e.id }))
    return listFilter.length > 0 ? [{ label: 'Tất cả', value: 'all' }].concat(listFilter) : []
  }, [ward, watchForm])

  const getDataOptions = async () => {
    try {
      const resRank = await getRankAllAPI()
      setRank(resRank?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getProgramOptions = async () => {
    const res = await getProgramListAPI({
      type: watchForm?.type,
      status: 'active',
      startDate: formateDate(Date.now(), 'YYYY-MM-DD')
    })
    setProgramOptions(res.data)
  }

  const getProgramDetail = async (id) => {
    const res = await getProgramDetailsAPI(id)
    if (res) {
      console.log('res', res)
      setProgramDetail(res.data)
    }
  }

  const getUserAccountOptions = async () => {
    try {
      const res = await getUserAccountsAPI()
      if (res.data) {
        const listFilter = res.data
          ?.filter((e) => watchForm?.wardIds?.includes(e.wardId) && watchForm?.userClients?.includes(e.client))
          ?.map((e) => ({ label: e.phone + ' - ' + e.name, value: e.id }))
        setUserAccountOptions(listFilter.length > 0 ? [{ label: 'Tất cả', value: 'all' }].concat(listFilter) : [])
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  useEffect(() => {
    if (watchForm?.type) {
      getProgramOptions()
    }
  }, [watchForm?.type])

  useEffect(() => {
    if (programDetail) {
      form.setFieldsValue({
        userClients: programDetail?.userClients,
        zone: programDetail?.zones?.map((e) => e.id),
        subzone: programDetail?.subZones?.map((e) => e.id),
        province: programDetail?.provinces?.map((e) => e.id),
        district: programDetail?.districts?.map((e) => e.id),
        wardIds: programDetail?.wards?.map((e) => e.id),
        technicianRankIds: programDetail?.ranks?.map((e) => e.id),
        userRankIds: programDetail?.ranks?.map((e) => e.id)
      })
    }
  }, [programDetail])

  useEffect(() => {
    getDataOptions()
  }, [])

  const onClickCancel = () => {
    setOnCancel(false)
    navigate('/manage-notification')
  }

  const checkClickCancel = () => {
    navigate('/manage-notification')
  }

  const onUpdateRequestPause = async (status, note) => {
    try {
      const res = await patchStatusContentAPI({
        id: parseInt(params.id, 10),
        status,
        node: note
      })
      if (res) {
        toast(status === 'pause' ? 'Tạm ngưng bài viết thành công' : 'Hiển thị lại bài viết thành công', {
          type: 'success'
        })
        // dispatch(getContentDetail(params.id))
        // dispatch(onGetManageContent({ ...filter, page: page }))
        dispatch(getContentDetail(params.id))
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
    closePopup()
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Spin spinning={loading}>
          <Form onFinish={onFinish} autoComplete='off' form={form} name='notificationForm'>
            <Container fluid>
              <Breadcrumb
                title={'Quản lý thông báo'}
                breadcrumbItem={
                  !isEdit
                    ? 'Xem chi tiết thông báo'
                    : props.router.location.pathname.includes('create')
                    ? 'Thêm mới thông báo'
                    : 'Chỉnh sửa thông báo'
                }
              />

              <Row>
                <Col lg='12'>
                  <Card>
                    <CardBody>
                      <div className='form-group '>
                        <Row className='mt-4'>
                          <Col sm='8'>
                            <Label className='form-label'>
                              {'Tiêu đề thông báo'}
                              {isEdit && <a className='text-danger'>*</a>}
                            </Label>
                            <Form.Item name='title' rules={[{ required: true, message: 'Vui lòng nhập tên bài viết' }]}>
                              <FormInput
                                placeholder={'Nhập tiêu đề thông báo'}
                                maxLength={MAX_LENGTH_NAME}
                                disabled={!isEdit}
                              />
                            </Form.Item>
                          </Col>
                          <Col sm='4'>
                            <Label className='form-label '>
                              {'Loại tài khoản'}
                              {isEdit && <a className='text-danger'>*</a>}
                            </Label>
                            <Form.Item
                              name='userClients'
                              rules={[{ required: true, message: 'Vui lòng chọn loại tài khoản' }]}
                            >
                              <FormSelect
                                mode='multiple'
                                allowClear
                                maxTagTextLength={10}
                                maxTagCount={2}
                                options={typeAccountProgramOptions}
                                onSelect={(value) => {
                                  const all = ['user', 'technician']
                                  if (value && value === 'all') {
                                    form.setFieldValue('userClients', [...all])
                                  }
                                  // form.setFieldValue("technicianRankIds", undefined)
                                  // form.setFieldValue("userRankIds", undefined)
                                }}
                                onChange={() => {
                                  form.setFieldValue('receivers', undefined)
                                }}
                                placeholder={'Chọn loại tài khoản'}
                                disabled={!isEdit}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm='4'>
                            <Label className='form-label'>
                              {'Miền'}
                              {isEdit && <a className='text-danger'>*</a>}
                            </Label>
                            <Form.Item name='zone' rules={[{ required: true, message: 'Vui lòng chọn miền' }]}>
                              <FormSelect
                                mode='multiple'
                                showSearch
                                allowClear
                                maxTagTextLength={10}
                                maxTagCount={2}
                                options={zoneOptions}
                                onChange={() => {
                                  form.setFieldValue('subzone', undefined)
                                  form.setFieldValue('province', undefined)
                                  form.setFieldValue('district', undefined)
                                  form.setFieldValue('wardIds', undefined)
                                }}
                                onSelect={(value) => {
                                  const all = zone?.map((e) => e.id)
                                  if (value && value === 'all') {
                                    return form.setFieldValue('zone', [...all])
                                  }
                                }}
                                placeholder={'Chọn miền'}
                                disabled={!isEdit}
                              />
                            </Form.Item>
                          </Col>
                          <Col sm='4'>
                            <Label className='form-label'>
                              {'Vùng'}
                              {isEdit && <a className='text-danger'>*</a>}
                            </Label>
                            <Form.Item name='subzone' rules={[{ required: true, message: 'Vui lòng chọn vùng' }]}>
                              <FormSelect
                                mode='multiple'
                                showSearch
                                allowClear
                                maxTagTextLength={10}
                                maxTagCount={2}
                                options={subzoneOptions}
                                onChange={() => {
                                  form.setFieldValue('province', undefined)
                                  form.setFieldValue('district', undefined)
                                  form.setFieldValue('wardIds')
                                }}
                                onSelect={(value) => {
                                  const all = subzone
                                    ?.filter((e) => watchForm.zone?.includes(e.zoneId))
                                    ?.map((e) => e.id)
                                  if (value && value === 'all') {
                                    return form.setFieldValue('subzone', [...all])
                                  }
                                }}
                                placeholder={'Chọn ' + ManageContentVN.detailInfor.form.region}
                                disabled={!isEdit || !watchForm?.zone?.length > 0}
                              />
                            </Form.Item>
                          </Col>
                          <Col sm='4'>
                            <Label className='form-label'>
                              {'Tỉnh/thành phố'}
                              {isEdit && <a className='text-danger'>*</a>}
                            </Label>
                            <Form.Item
                              name='province'
                              rules={[{ required: true, message: 'Vui lòng chọn tỉnh/thành phố' }]}
                            >
                              <FormSelect
                                mode='multiple'
                                showSearch
                                allowClear={<i className='mdi mdi-close-circle-outline mdi-24px ' />}
                                maxTagTextLength={10}
                                maxTagCount={2}
                                options={provinceOptions}
                                onChange={() => {
                                  form.setFieldValue('district', undefined)
                                  form.setFieldValue('wardIds', undefined)
                                }}
                                onSelect={(value) => {
                                  const all = province
                                    ?.filter((e) => watchForm.subzone?.includes(e.subZoneId))
                                    ?.map((e) => e.id)
                                  if (value && value === 'all') {
                                    return form.setFieldValue('province', [...all])
                                  }
                                }}
                                placeholder={'Chọn ' + ManageContentVN.detailInfor.form.city}
                                disabled={!isEdit || !watchForm?.subzone?.length > 0}
                              />
                            </Form.Item>
                          </Col>
                          <Col sm='4'>
                            <Label className='form-label'>
                              {'Quận/huyện'}
                              {isEdit && <a className='text-danger'>*</a>}
                            </Label>
                            <Form.Item
                              name='district'
                              rules={[{ required: true, message: 'Vui lòng chọn quận/huyện' }]}
                            >
                              <FormSelect
                                mode='multiple'
                                showSearch
                                allowClear
                                maxTagTextLength={10}
                                maxTagCount={2}
                                options={districtOptions}
                                onChange={() => {
                                  form.setFieldValue('wardIds', undefined)
                                }}
                                onSelect={(value) => {
                                  const all = district
                                    ?.filter((e) => watchForm.province?.includes(e.parentId))
                                    ?.map((e) => e.id)
                                  if (value && value === 'all') {
                                    return form.setFieldValue('district', [...all])
                                  }
                                }}
                                placeholder={'Chọn ' + ManageContentVN.detailInfor.form.district}
                                disabled={!isEdit || !watchForm?.province?.length > 0}
                              />
                            </Form.Item>
                          </Col>
                          <Col sm='4'>
                            <Label className='form-label'>
                              {'Phường xã'}
                              {isEdit && <a className='text-danger'>*</a>}
                            </Label>
                            <Form.Item name='wardIds' rules={[{ required: true, message: 'Vui lòng chọn phường/xã' }]}>
                              <FormSelect
                                mode='multiple'
                                showSearch
                                allowClear
                                maxTagTextLength={10}
                                maxTagCount={2}
                                options={wardOptions}
                                onSelect={(value) => {
                                  const all = ward
                                    ?.filter((e) => watchForm.district?.includes(e.parentId))
                                    ?.map((e) => e.id)
                                  if (value && value === 'all') {
                                    return form.setFieldValue('wardIds', [...all])
                                  }
                                }}
                                placeholder={'Chọn ' + ManageContentVN.detailInfor.form.ward}
                                disabled={!isEdit || !watchForm?.district?.length > 0}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm='4'>
                            <Label className='form-label'>
                              {'Tài khoản nhận thông báo'}
                              {isEdit && <a className='text-danger'>*</a>}
                            </Label>
                            <Form.Item
                              name='receivers'
                              rules={[{ required: true, message: 'Vui lòng tài khoản nhận thông báo' }]}
                            >
                              <FormSelect
                                mode='multiple'
                                showSearch
                                allowClear
                                maxTagTextLength={10}
                                maxTagCount={2}
                                options={userAccountOptions}
                                onSelect={(value) => {
                                  const all = userAccountOptions?.filter((x) => x.value !== 'all')?.map((e) => e.value)
                                  if (value && value === 'all') {
                                    return form.setFieldValue('receivers', [...all])
                                  }
                                }}
                                placeholder={'Chọn số điện thoại nhận thông báo'}
                                disabled={!isEdit}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm='4'>
                            <div className='d-flex flex-row' style={{ height: '25%' }}>
                              <Col sm='6'>
                                <Label className='form-label  me-5'>
                                  {'Thời gian gửi'}
                                  {isEdit && <a className='text-danger'>*</a>}
                                </Label>
                              </Col>
                              <Col sm='6'>
                                <Form.Item name='applySettingSending'>
                                  <Radio.Group
                                    options={ManageNotificaionVN.form.applySettingSending.options}
                                    optionType='default'
                                    disabled={!isEdit}
                                  />
                                </Form.Item>
                              </Col>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          {watchForm?.applySettingSending == true && (
                            <Col sm='6'>
                              <Label className='form-label'>
                                {'Ngày giờ'}
                                {/* {isEdit && <a className='text-danger'>*</a>} */}
                              </Label>
                              <div className='d-flex flex-row'>
                                <Col sm='5' className='me-1'>
                                  <Form.Item
                                    name='sendDate'
                                    // rules={[{ required: true, message: 'Vui lòng chọn ngày gửi' }]}
                                  >
                                    <FormDate
                                      disabled={!isEdit}
                                      // disabledDate={(current) => dayjs().isBefore(current, 'day')}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col sm='6'>
                                  <Form.Item
                                    name='sendTime'
                                    // rules={[{ required: true, message: 'Vui lòng chọn thời gian gửi' }]}
                                  >
                                    <TimePicker
                                      className='w-50'
                                      size='large'
                                      disabled={!isEdit}
                                      placeholder='hh:mm:ss'
                                    />
                                  </Form.Item>
                                </Col>
                              </div>
                            </Col>
                          )}
                        </Row>

                        <Row className='mt-4'>
                          <h5>{'Nội dung'}</h5>
                          <Col sm='12'>
                            {/* <EditorCustom data={editorState} setData={setEditorState} disabled={!isEdit} /> */}
                            <Form.Item name='body'>
                              <TextArea
                                placeholder={'Nhập nội dung thông báo'}
                                disabled={!isEdit}
                                maxLength={2000}
                                onBlur={(e) => form.setFieldValue('body', e.target.value.trim())}
                                autoSize={{ minRows: 3, maxRows: 5 }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>

                      <div className='d-flex justify-content-between mt-4'>
                        <div></div>
                        {isEdit ? (
                          <div className='hstack gap-2 justify-content-center mt-2'>
                            <span
                              onClick={() => {
                                checkClickCancel()
                              }}
                              className='btn btn-soft-primary'
                            >
                              {'Huỷ'}
                            </span>
                            <button
                              type='button'
                              className='btn btn-primary'
                              onClick={debounce(() => {
                                form.submit()
                              }, 500)}
                            >
                              {'Lưu'}
                            </button>
                          </div>
                        ) : (
                          <div className='hstack gap-2 justify-content-center mt-2'>
                            <span
                              onClick={() => {
                                checkClickCancel()
                              }}
                              className='btn btn-soft-primary'
                            >
                              {ManageContentVN.cancel}
                            </span>
                            <button
                              type='button'
                              className='btn btn-primary'
                              onClick={debounce(() => {
                                navigate(`/manage-notification/edit/${params.id}`)
                              }, 500)}
                            >
                              Chỉnh sửa
                            </button>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <ConfirmModal
                show={showReject}
                onConfirmClick={(note) => {
                  onUpdateRequest('rejected', note)
                }}
                onCloseClick={closePopup}
                hasInput
                title={'Từ chối bài viết'}
                description='Bạn có chắc chắn muốn từ chối phê duyệt bài viết?'
              />
              <ConfirmModal
                show={showApprove}
                onConfirmClick={(note) => onUpdateRequest('active')}
                onCloseClick={closePopup}
                title={'Xác nhận phê duyệt bài viết'}
                description='Bạn có chắc chắn muốn phê duyệt bài viết này?'
              />
              <ConfirmModal
                show={onCancel}
                onConfirmClick={onClickCancel}
                onCloseClick={() => setOnCancel(false)}
                title={`Xác nhận`}
                description={`Thay đổi sẽ không được lưu. Bạn có chắc chắn muốn thoát không?`}
              />
              <ConfirmModal
                show={showPause}
                icon={
                  <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
                    <i className='mdi mdi-delete-outline'></i>
                  </div>
                }
                hasInput
                onConfirmClick={(note) => {
                  onUpdateRequestPause('pause', note)
                }}
                onCloseClick={() => setShowPause(false)}
                title='Xác nhận tạm ngưng bài viết'
                description={`Bài viết sẽ không còn được hiển thị ở các module liên quan trên Mobile App. Bạn có chắc chắn muốn tạm ngưng hiển thị bài viết?`}
              />
              <ConfirmModal
                show={showPlay}
                onConfirmClick={(note) => {
                  onUpdateRequestPause('active')
                }}
                onCloseClick={() => setShowPlay(false)}
                // hasInput
                title={'Xác nhận hiển thị lại bài viết'}
                description='Bài viết sẽ được hiển thị ở các module liên quan trên Mobile App. Bạn có chắc chắn muốn hiển thị lại bài viết?'
              />
            </Container>
          </Form>
        </Spin>
      </div>
    </React.Fragment>
  )
}

export default withRouter(NotificationDetail)
