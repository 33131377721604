import { Form, Table, Button, Image, Spin } from 'antd'
import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { MenuOutlined } from '@ant-design/icons'
import { Container, Card, CardBody, Row, Col } from 'reactstrap'

import Breadcrumb from 'components/Common/Breadcrumb'
import { ClassStructureVN } from 'constants/vn'
import { toast } from 'react-toastify'
import {
  getContentAllAPI,
  getBanerListAPI,
  getContentDetailAPI,
  updateBanerAPI,
  createBannerAPI
} from 'helpers/backend_helper'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { formateDate } from 'helpers/global_helper'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
const type = 'DraggableBodyRow'
import { v4 as uuidv4 } from 'uuid'
import { isEqual } from 'lodash'
import { useNavigate } from 'react-router-dom'
import ConfirmModal from 'components/Common/ConfirmModal'
import { BANNER_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import usePermission from 'hooks/usePermission'

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = useRef(null)
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {}
      if (dragIndex === index) {
        return {}
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
      }
    },
    drop: (item) => {
      moveRow(item.index, index)
    }
  })
  const [, drag] = useDrag({
    type,
    item: {
      index
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })
  drop(drag(ref))
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{
        cursor: 'move',
        ...style
      }}
      {...restProps}
    />
  )
}

const ConfigBanner = () => {
  //meta title
  document.title = 'Banner Config | HPG'
  const screenCode = useMemo(() => SCREEN_CODE.BANNER_MANAGEMENT, [])

  const navigate = useNavigate()
  const [defaultTasks, setDefaultTasks] = useState([])

  const [form] = Form.useForm()
  const watchForm = Form.useWatch([], form)
  const dispatch = useDispatch()
  const [optionsAnnounce, setOptionsAnnounce] = useState([])
  const [data, setData] = useState([])
  const [submittable, setSubmittable] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errDataTable, setErrDataTable] = useState(true)
  const [initData, setInitData] = useState()
  const [onCancel, setOnCancel] = useState(false)
  const [count, setCount] = useState(0)
  const hasPermission = usePermission({ screenCode, code: BANNER_MANAGEMENT_CODE.ADMIN_DELETE_BANNER })

  useEffect(() => {
    handleGetOptionsContent()
    handleGetDetail()
  }, [])

  useEffect(() => {
    console.log('dataUseEffect', data)
  }, [data])

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true)
      },
      () => {
        setSubmittable(false)
      }
    )
  }, [watchForm])

  useEffect(() => {
    if (data) {
      handleCheckAnnounceIdAll()
      checkErrDataTable()
    }
  }, [data, watchForm])

  const handleGetOptionsContent = async () => {
    try {
      const res = await getContentAllAPI()
      if (res?.data) {
        const list = []
        res?.data?.map((item) => {
          list.push({
            label: item.name,
            value: item.id
          })
        })

        setOptionsAnnounce(list)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleGetDetail = async () => {
    setLoading(true)
    try {
      const res = await getBanerListAPI({ sort_field: 'priority', sort_order: 'asc', limit: 20 })

      if (res) {
        const list_source = []
        setDefaultTasks(res.data)
        res.data.map((item, index) => {
          const key = uuidv4()
          list_source.push({
            key: key,
            image: item?.announce?.image,
            name: item?.announce?.name,
            announceId: item?.announceId,
            duration: formateDate(item?.announce?.startDate) + ' - ' + formateDate(item?.announce?.endDate),
            priority: item?.priority,
            index: index,
            id: item?.id
          })

          form.setFieldsValue({
            [`announce_id-${key}`]: item?.announce?.id
          })
        })
        setCount(list_source.length)
        setInitData(JSON.parse(JSON.stringify(list_source)))
        setData([...list_source])
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const columns = useMemo(
    () => [
      {
        title: 'Thứ tự hiển thị',
        dataIndex: 'priority',
        width: 150,
        render: () => (
          <MenuOutlined
            style={{
              cursor: 'grab',
              color: '#999'
            }}
          />
        )
      },
      {
        title: 'Tên bài viết',
        dataIndex: 'announceId',
        width: 300,
        render: (_, record) => {
          return (
            <Form.Item style={{ margin: 0, height: '62px' }} name={`announce_id-${record.key}`}>
              <FormSelect
                options={optionsAnnounce}
                onChange={(e) => handleUpdateBanner(e, record.key, `announce_id-${record.key}`)}
                placeholder={'Chọn bài viết'}
              />
            </Form.Item>
          )
        }
      },
      {
        title: 'Ảnh',
        dataIndex: 'image',
        render: (_, record) => {
          if (record.image) {
            return <Image width={120} height={60} style={{ objectFit: 'cover' }} src={record.image} />
          } else {
            return '--'
          }
        }
      },
      {
        title: 'Thời gian hiển thị',
        dataIndex: 'duration'
      },

      {
        title: ClassStructureVN.form.table.action,
        dataIndex: 'action',
        show: hasPermission,
        render: (_, record) => {
          return (
            <div style={{ height: '62px' }}>
              <Button
                size='large'
                onClick={() => {
                  handleDelete(record.key, record.id)
                }}
              >
                <i className='mdi mdi-delete-outline' />
              </Button>
            </div>
          )
        }
      }
    ],
    [hasPermission, optionsAnnounce, data]
  )

  const permissionColumns = useMemo(() => columns.filter((item) => item.show !== false), [columns])

  const handleUpdateBanner = useCallback(
    async (value, key, name) => {
      setLoading(true)
      const contentDetail = await getContentDetailAPI({ id: value })
      const findIndex = data.findIndex((item) => key === item.key)
      if (findIndex != -1 && contentDetail) {
        const newData = [...data]
        newData[findIndex].announceId = value
        newData[findIndex].name = contentDetail.data.name
        newData[findIndex].image = contentDetail.data.image
        newData[findIndex].duration =
          formateDate(contentDetail.data.startDate) + ' - ' + formateDate(contentDetail.data.startDate)

        form.setFieldsValue({
          [name]: value
        })
        setData(newData)
      }
      setLoading(false)
    },
    [data]
  )

  const handleCheckAnnounceIdAll = () => {
    const list = layItemTrungAnnounceId(data)
    const list_khong_trung = data.filter((item) => !list.includes(item))

    list.map((item) => {
      form.setFields([{ name: `announce_id-${item.key}`, errors: ['Bài viết không được trùng'] }])
    })

    list_khong_trung.map((item) => {
      form.setFields([{ name: `announce_id-${item.key}`, errors: null }])
    })
  }

  function layItemTrungAnnounceId(data) {
    const itemsTrungNhau = []

    for (let i = 0; i < data.length - 1; i++) {
      for (let j = i + 1; j < data.length; j++) {
        if (data[i].announceId == undefined) {
          continue
        }
        if (data[i].announceId === data[j].announceId) {
          itemsTrungNhau.push(data[i])
          itemsTrungNhau.push(data[j])
        }
      }
    }

    return itemsTrungNhau
  }

  const checkErrDataTable = () => {
    const dataError = form.getFieldsError()
    const dataValue = form.getFieldsValue()

    const check = []

    dataError.map((item) => {
      if (dataValue[item.name[0]] == undefined || item.errors.length > 0) {
        check.push(false)
      } else {
        check.push(true)
      }
    })

    if (!check.includes(false)) {
      setErrDataTable(false)
    } else {
      setErrDataTable(true)
    }
  }

  const handleAdd = () => {
    // const newData = {
    //   key: uuidv4(),
    //   image: '',
    //   name: '',
    //   id: '',
    //   duration: '',
    //   priority: data.length + 1
    // }
    // setData([...data, newData])

    setData((prevData) => [
      ...prevData,
      {
        key: uuidv4(),
        image: '',
        name: '',
        id: '',
        duration: '',
        priority: data.length + 1
      }
    ])

    setCount(count + 1)
  }

  const handleDelete = (key, id) => {
    const dataSource = [...data]
    setData(dataSource.filter((item) => item.key !== key))
  }

  const handleSave = async () => {
    const tasks = data.map((item, index) => {
      if (item.key == 0) {
        return
      }

      if (item.id == '' || item.id == undefined) {
        return {
          announceId: item.announceId,
          priority: index + 1
        }
      } else {
        return {
          id: item.id,
          announceId: item.announceId,
          priority: index + 1
        }
      }
    })
    const list_taks = tasks.filter((item) => item != null)

    if (defaultTasks?.length <= 0) {
      try {
        const res = await createBannerAPI({ tasks: list_taks })

        if (res) {
          toast('Lưu thành công', { type: 'success' })
          navigate('/manage-banner')
        }
      } catch (error) {
        toast(error.response.data.message, { type: 'error' })
      }
    } else {
      try {
        const res = await updateBanerAPI({ tasks: list_taks })

        if (res) {
          toast('Lưu thành công', { type: 'success' })
          navigate('/manage-banner')
        }
      } catch (error) {
        toast(error.response.data.message, { type: 'error' })
      }
    }
  }

  const onClickCancel = () => {
    setOnCancel(false)
    navigate('/manage-banner')
  }

  const checkClickCancel = () => {
    if (!isEqual(data, initData)) {
      setOnCancel(true)
    } else {
      navigate('/manage-banner')
    }
  }

  // drag drop
  const components = {
    body: {
      row: DraggableBodyRow
    }
  }
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex]
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        })
      )
    },
    [data]
  )

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title={'Thiết lập cấu hình banner'} breadcrumbItem={'Thiết lập cấu hình banner'} />
          <Card>
            <Spin spinning={loading}>
              <CardBody>
                <Form form={form}>
                  <Row>
                    <Col xl={12}>
                      {data && data.length > 0 && (
                        <>
                          <DndProvider backend={HTML5Backend}>
                            <Table
                              pagination={false}
                              columns={permissionColumns}
                              dataSource={data}
                              components={components}
                              onRow={(_, index) => {
                                const attr = {
                                  index,
                                  moveRow
                                }
                                return attr
                              }}
                            />
                          </DndProvider>
                        </>
                      )}
                      <PermissionWrapper screenCode={screenCode} code={BANNER_MANAGEMENT_CODE.ADMIN_CREATE_BANNER}>
                        {count <= 20 && (
                          <Button
                            className='mt-1 d-flex align-items-center gap-1'
                            onClick={handleAdd}
                            style={{
                              marginBottom: 16,
                              backgroundColor: 'none',
                              border: 'none',
                              color: '#4069e5'
                            }}
                          >
                            <i className='bx bxs-plus-circle font-size-20' /> <span>Thêm banner</span>
                          </Button>
                        )}
                      </PermissionWrapper>
                    </Col>
                  </Row>
                </Form>
                <Row>
                  <Col xl={12} className='d-flex gap-2 justify-content-end mt-4'>
                    <button onClick={() => checkClickCancel()} type='button' className='btn btn-soft-secondary w-14 '>
                      Hủy
                    </button>
                    <button
                      type={'submit'}
                      className='btn btn-primary w-14'
                      disabled={!submittable || errDataTable || isEqual(data, initData)}
                      onClick={() => {
                        handleSave()
                      }}
                    >
                      {'Lưu'}
                    </button>
                  </Col>
                </Row>
              </CardBody>
            </Spin>
          </Card>
          <ConfirmModal
            show={onCancel}
            onConfirmClick={onClickCancel}
            onCloseClick={() => setOnCancel(false)}
            title={`Xác nhận`}
            description={`Thay đổi sẽ không được lưu. Bạn có chắc chắn muốn thoát không?`}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ConfigBanner
