import { Form, Radio, Select } from 'antd'
import { FormDateMultiple } from 'components/Common/form-date-multiple/FormDateMultiple'
import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'
import { FormInputNumber } from 'components/Common/form-input-number/FormInputNumber'
import { FormInput } from 'components/Common/form-input/FormInput'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { MAX_LENGTH_100, typeAccountProgramOptions, TypeProgramOptions } from 'constants/constants'
import { BankVN, ManageProgramVN } from 'constants/vn'
import dayjs from 'dayjs'
import { checkFileSizeValid, formateDate } from 'helpers/global_helper'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Card, CardBody, Col, Label, Row } from 'reactstrap'
import { createSelector } from 'reselect'
import {
  getAccountRankListAPI,
  getCustomerCareProductListAPI,
  getSMSBrandnameListAPI,
  importFileAPI
} from 'helpers/backend_helper'
import * as url from 'helpers/url_helper'
import Spinners from 'components/Common/Spinner'
import TableContainer from 'components/Common/TableContainer'
import { CustomerCareProductListStatus } from 'pages/ManageAccumulation/ManageAccumulationCol'

const ProductTab = ({
  watchForm,
  form,
  industry,
  industrySector,
  category,
  model,
  channel,
  rank,
  type,
  // setLoading,
  parallelProgram
}) => {
  // const type = "create"
  const isEdit = type !== 'view'

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    province: app.province,
    district: app.district,
    ward: app.ward,
    warehouseZone: app.zone,
    subzone: app.subzone
  }))
  const { province, district, ward, warehouseZone, subzone } = useSelector(LayoutProperties)

  const uploadRef = useRef(null)
  const [listTable, setListTable] = useState([])
  const [industrySectorSelected, setIndustrysectorSelected] = useState([])
  const [applyClientTypeSelected, setApplyClientTypeSelected] = useState([])
  const [applyWardSelected, setApplyWardSelected] = useState([])
  const [accountRank, setAccountRank] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [filter, setFilter] = useState()
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState()
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })

  useEffect(() => {
    form.setFieldValue('category', undefined)
    form.setFieldValue('model', undefined)
    getDataOptions()
  }, [industrySectorSelected])

  useEffect(() => {
    form.setFieldValue('rank', undefined)
    getDataOptions()
  }, [applyClientTypeSelected])

  useEffect(() => {
    getDataOptions()
  }, [applyWardSelected])

  useEffect(() => {
    handleGetListTable()
  }, [filter])

  const getDataOptions = async () => {
    try {
      const ward_selected = applyWardSelected.map((id) => ward.find((ward) => ward.id === id))
      const applyDistrictSelected = ward_selected?.map((e) => e.parentId)

      const resAccountRank = await getAccountRankListAPI({
        industry_sector_ids: industrySectorSelected,
        clients: applyClientTypeSelected,
        district_ids: Array.from(new Set(applyDistrictSelected))
      })
      console.log('resAccountRank?.data', resAccountRank?.data)
      setAccountRank(resAccountRank?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetListTable = async () => {
    try {
      const res = await getCustomerCareProductListAPI(filter)

      if (res.data) {
        setListTable(res.data)
      }

      if (res.meta) {
        setTotal(res.meta.totalItems)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const goToPage = (page) => {
    setFilter({ ...filter, page })
    setPage(page)
  }

  const setGlobalFilterUser = async (data) => {
    data.serial = data.search
    data.code = data.search
    setFilter(data)
    handleGetListTable()

    setPage(1)
  }

  const columns = useMemo(
    () => [
      // {
      //   Header: 'STT',
      //   accessor: 'stt',
      //   filterable: true,
      //   disableSortBy: true,
      //   hideSort: true,
      //   Cell: (cellProps) => {
      //     return (page - 1) * 10 + cellProps.row.index + 1
      //   }
      // },
      {
        Header: 'Serial sản phẩm',
        accessor: 'serial',
        filterable: true,
        disableSortBy: true,
        hideSort: true
        // Cell: (cellProps) => {
        //   return <Name {...cellProps} />
        // }
      },
      {
        Header: 'Ngành',
        accessor: 'industry.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true
        // Cell: (cellProps) => {
        //   return <Abbreviation {...cellProps} />
        // }
      },
      {
        Header: 'Ngành hàng',
        accessor: 'industrySector.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true
        // Cell: (cellProps) => {
        //   return <Abbreviation {...cellProps} />
        // }
      },
      {
        Header: 'Nhóm sản phẩm',
        accessor: 'category.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true
        // Cell: (cellProps) => {
        //   return <Abbreviation {...cellProps} />
        // }
      },
      {
        Header: 'Model',
        accessor: 'model.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true
        // Cell: (cellProps) => {
        //   return <Abbreviation {...cellProps} />
        // }
      },
      {
        Header: 'Miền',
        accessor: 'zone.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true
        // Cell: (cellProps) => {
        //   return <Abbreviation {...cellProps} />
        // }
      },
      {
        Header: 'Kênh kinh doanh',
        accessor: 'channel.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true
        // Cell: (cellProps) => {
        //   return <Abbreviation {...cellProps} />
        // }
      },
      {
        Header: 'Ngày xuất kho',
        // accessor: 'abbreviation',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return cellProps.row.original ? formateDate(cellProps.row.original?.exportDate) : '--'
        }
      },
      {
        Header: 'Mã bảo hành',
        // accessor: 'abbreviation',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return cellProps?.row?.original?.isActivatedWarranty == true ? 'Có mã' : 'Không có mã'
        }
      },
      {
        Header: 'Loại sản phẩm',
        // accessor: 'abbreviation',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return cellProps?.row?.original?.type
            ? cellProps?.row?.original?.type === 'hot'
              ? 'Cục nóng'
              : 'Cục lạnh'
            : '--'
        }
      },
      {
        Header: 'Trạng thái',
        // accessor: 'abbreviation',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <CustomerCareProductListStatus {...cellProps} />
        }
      }
    ],
    [filter, page]
  )

  return (
    <React.Fragment>
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <Card>
          <CardBody>
            <TableContainer
              columns={columns}
              data={listTable}
              isGlobalFilter={true}
              setGlobalFilterUser={setGlobalFilterUser}
              isManageBankGlobalFilter={true}
              isPagination={true}
              iscustomPageSizeOptions={false}
              isShowingPageLength={false}
              customPageSize={10}
              placeholderSearch={'Nhập từ khoá Serial'}
              tableClass='table-bordered align-middle nowrap mt-2'
              paginationDiv='col-sm-12 col-md-7'
              pagination='pagination justify-content-end pagination-rounded'
              useAPIChangePaging
              currentPage={page}
              gotoPageCustom={goToPage}
              total={total}
            />
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  )
}
export default ProductTab
