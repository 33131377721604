import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//import components

import {
  getBankList,
  setTypePopupBank,
  getBankDetail,
  getBankDetailSuccess,
  getContactList,
  setTypePopupContact,
  getContactDetail,
  getContactDetailSuccess
} from 'store/actions'

// import { Name, Abbreviation } from '././ManageBankCol'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
// import { BankVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import {
  deleteBankAPI,
  deleteContactAPI,
  getAuditLogAPI,
  getAuditLogDetailAPI,
  getSMSBrandnameListAPI
} from 'helpers/backend_helper'
import AuditLogDetailModal from './components/auditLogDetailModal'
// import { AREA_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import { Space } from 'antd'
import { BankVN } from 'constants/vn'
import { AREA_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import { TableRowSpan } from 'components/Common/TableCustom'
import { formateDateTime } from 'helpers/global_helper'
import AuditLogFilter from './components/AuditLogFilter'
import { UserName } from 'pages/ManageProduct/ManageProductCol'

function ManageAuditLog() {
  //meta title
  document.title = 'Manage Audit Log CMS'
  const screenCode = useMemo(() => SCREEN_CODE.AREA_MANAGEMENT, [])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectContactsState = (state) => state.ContactsReducer

  const [page, setPage] = useState(1)
  const [listTable, setListTable] = useState([])
  const [total, setTotal] = useState()
  const [isLoading, setLoading] = useState(false)
  const [onDelete, setOnDelete] = useState(false)
  const [selected, setSelected] = useState()
  const [filter, setFilter] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })
  const [detailTable, setDetailTable] = useState([])

  // useEffect(() => {
  //   dispatch(getContactList())
  // }, [dispatch])

  useEffect(() => {
    handleGetListTable()
  }, [filter])

  const handleGetListTable = async () => {
    try {
      // const res = await getPointConfigListAPI(filter)
      const res = await getAuditLogAPI(filter)

      if (res.data) {
        setListTable(res.data)
      }

      if (res.meta) {
        setTotal(res.meta.totalItems)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleGetAditLogDetail = async (id) => {
    try {
      // const res = await getPointConfigListAPI(filter)
      const res = await getAuditLogDetailAPI({ id: id })

      if (res?.data) {
        // setListTable(res.data)
        let detailData = res?.data

        const oldMetadata = {
          id: detailData?.oldMetadata ? detailData?.oldMetadata?.id : '',
          serial: detailData?.oldMetadata ? detailData?.oldMetadata?.serial : '',
          zone: detailData?.oldMetadata ? detailData?.oldMetadata?.zone?.name : '',
          industry: detailData?.oldMetadata ? detailData?.oldMetadata?.industry?.name : '',
          industrySector: detailData?.oldMetadata ? detailData?.oldMetadata?.industrySector?.name : '',
          category: detailData?.oldMetadata ? detailData?.oldMetadata?.category?.name : '',
          model: detailData?.oldMetadata ? detailData?.oldMetadata?.model?.name : '',
          channel: detailData?.oldMetadata ? detailData?.oldMetadata.channel?.name : '',
          exportDate: detailData?.oldMetadata ? formateDateTime(detailData?.oldMetadata?.exportDate) : '',
          type: detailData?.oldMetadata ? (detailData?.oldMetadata?.type === 'hot' ? 'Cục nóng' : 'Cục lạnh') : '',
          requireActivationCode: detailData?.oldMetadata ? detailData?.oldMetadata?.model?.requireActivationCode : '',
          status: detailData?.oldMetadata
            ? detailData?.oldMetadata?.status === 'active'
              ? 'Chưa tích luỹ'
              : detailData?.oldMetadata?.status === 'inactive'
              ? 'Thu hồi'
              : detailData?.oldMetadata?.status === 'accumulated'
              ? 'Đã tích luỹ'
              : 'Đã kích hoạt'
            : ''
        }

        const newMetadata = {
          id: detailData?.newMetadata ? detailData?.newMetadata?.id : '',
          serial: detailData?.newMetadata ? detailData?.newMetadata?.serial : '',
          zone: detailData?.newMetadata ? detailData?.newMetadata?.zone?.name : '',
          industry: detailData?.newMetadata ? detailData?.newMetadata?.industry?.name : '',
          industrySector: detailData?.newMetadata ? detailData?.newMetadata?.industrySector?.name : '',
          category: detailData?.newMetadata ? detailData?.newMetadata?.category?.name : '',
          model: detailData?.newMetadata ? detailData?.newMetadata?.model?.name : '',
          channel: detailData?.newMetadata ? detailData?.newMetadata?.channel?.name : '',
          exportDate: detailData?.newMetadata ? formateDateTime(detailData?.newMetadata?.exportDate) : '',
          type: detailData?.newMetadata ? (detailData?.newMetadata?.type === 'hot' ? 'Cục nóng' : 'Cục lạnh') : '',
          requireActivationCode: detailData?.newMetadata ? detailData?.newMetadata?.model?.requireActivationCode : '',
          status: detailData?.newMetadata
            ? detailData?.newMetadata?.status === 'active'
              ? 'Chưa tích luỹ'
              : detailData?.newMetadata?.status === 'inactive'
              ? 'Thu hồi'
              : detailData?.newMetadata?.status === 'accumulated'
              ? 'Đã tích luỹ'
              : 'Đã kích hoạt'
            : ''
        }

        const alias = [
          'Serial sản phẩm',
          'Miền',
          'Ngành',
          'Ngành hàng',
          'Nhóm sản phẩm',
          'Model',
          'Kênh kinh doanh',
          'Ngày xuất kho',
          'Loại sản phẩm',
          'Mã bảo hành',
          'Trạng thái'
        ]

        // Hàm để tạo mảng dữ liệu
        const generateDataArray = () => {
          const fields = [
            { key: 'serial', alias: alias[0] },
            { key: 'zone', alias: alias[1] },
            { key: 'industry', alias: alias[2] },
            { key: 'industrySector', alias: alias[3] },
            { key: 'category', alias: alias[4] },
            { key: 'model', alias: alias[5] },
            { key: 'channel', alias: alias[6] },
            { key: 'exportDate', alias: alias[7] },
            { key: 'type', alias: alias[8] },
            { key: 'requireActivationCode', alias: alias[9] },
            { key: 'status', alias: alias[10] }
          ]

          return fields.map((field) => ({
            alias: field.alias,
            oldMetadata: oldMetadata[field.key],
            newMetadata: newMetadata[field.key]
          }))
        }

        let detailTable = generateDataArray()
        setDetailTable(detailTable)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'STT',
        accessor: 'stt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (page - 1) * 10 + cellProps.row.index + 1
        }
      },
      {
        Header: 'Người cập nhật',
        accessor: (cell) => {
          return cell
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <UserName {...cellProps} />
        }
      },
      {
        Header: 'Thời gian cập nhật',
        accessor: 'updatedAt',
        filterable: true,
        hideSort: true,
        Cell: (cellProps) => {
          return formateDateTime(cellProps.value)
        }
      },
      {
        Header: 'Hoạt động',
        accessor: 'type',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return cellProps?.value === 'update' ? 'Chỉnh sửa' : 'Xoá'
        }
      },
      {
        Header: 'Serial',
        accessor: 'product.serial',
        filterable: true,
        disableSortBy: true,
        hideSort: true
      },
      {
        Header: 'Model',
        accessor: 'product.model.name',
        filterable: true,
        disableSortBy: true,
        hideSort: true
      },
      {
        Header: 'Nội dung',
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (
            <ul className='list-unstyled hstack gap-1 mb-0'>
              {cellProps.row.original.type !== 'delete' && (
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <Link
                    to={`#`}
                    onClick={() => {
                      setOpenModal(true)
                      dispatch(setTypePopupContact('view'))
                      handleGetAditLogDetail(cellProps?.row?.original?.id)
                    }}
                    className='btn btn-sm btn-soft-primary'
                    id={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    <i className='mdi mdi-eye-outline' />
                  </Link>
                </li>
              )}
            </ul>
          )
        }
      }
    ],
    []
  )

  const setGlobalFilterUser = async (data) => {
    data.start_date = data.start_date1?.startOf('day')?.toDate()
    data.end_date = data.end_date1?.endOf('day')?.toDate()
    data.email = data.email || undefined
    setFilter(data)
    handleGetListTable()
    setPage(1)
  }

  const onClickDelete = async () => {
    try {
      const res = await deleteContactAPI({ id: selected.id, type: selected.type })
      if (res) {
        toast('Xoá thông tin liên hệ thành công', { type: 'success' })
        dispatch(getContactList(filter))
        setOnDelete(false)
      }
    } catch (error) {
      toast.error(error.response.data.message, { type: 'error' })
    }
  }

  const goToPage = (page) => {
    dispatch(getContactList({ ...filter, page }))
    setFilter({ ...filter, page })
    setPage(page)
  }

  const handleResetFilter = () => {
    dispatch(getContactList({ page: 1 }))
    setFilter({ page: 1 })
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{'Lịch sử thay đổi thông tin sản phẩm'}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'></div>
                  </CardBody>
                  <CardBody>
                    <Space size='middle' direction='vertical' className='w-100'>
                      <AuditLogFilter handleFilter={setGlobalFilterUser} handleResetFilter={handleResetFilter} />
                      <TableRowSpan
                        columns={columns}
                        data={listTable}
                        pagination={pagination}
                        setPagination={setPagination}
                        gotoPageCustom={goToPage}
                        currentPage={page}
                        total={total}
                        isShowTotal={true}
                      />
                    </Space>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      {openModal && (
        <AuditLogDetailModal
          show={openModal}
          filter={filter}
          onCloseClick={() => {
            setOpenModal(false)
            dispatch(getContactDetailSuccess(null))
          }}
          setPage={setPage}
          dataTable={detailTable}
        />
      )}
      <ConfirmModal
        show={onDelete}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-outline'></i>
          </div>
        }
        onConfirmClick={onClickDelete}
        onCloseClick={() => setOnDelete(false)}
        title={`Xác nhận xóa thông tin liên hệ `}
        description={`Bạn có chắc chắn muốn xóa thông tin liên hệ ?`}
      />
    </React.Fragment>
  )
}

export default ManageAuditLog
