import { AccumulationVN } from 'constants/vn'
import React, { useEffect, useMemo, useState } from 'react'
import { Col, Label, Modal, ModalBody, Row, UncontrolledTooltip } from 'reactstrap'

// Formik validation
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { filterDataHaveValue, formateDate } from 'helpers/global_helper'
import { getAccumulationList, setTypePopupAccumulation } from 'store/actions'
import { getModelBySerialAPI, updateAccumulationAPI } from 'helpers/backend_helper'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { Form } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { debounce, isEqual } from 'lodash'
import { MAX_LENGTH_50 } from 'constants/constants'
import { Locate, StatusAccumulation } from '../ManageAccumulationCol'

const AddEditAccumulation = ({ show, onCloseClick, selected, page }) => {
  const [form] = Form.useForm()
  const watchModel = Form.useWatch('model', form)
  const watchForm = Form.useWatch([], form)
  const [submittable, setSubmittable] = useState()
  const [model, setModel] = useState()
  const [channel, setChannel] = useState()
  const [initData, setInitData] = useState({})

  const dispatch = useDispatch()

  const selectAccumulationsState = (state) => state.AccumulationReducer
  const AccumulationsProperties = createSelector(selectAccumulationsState, (reducer) => ({
    type: reducer.type,
    accumulationDetail: reducer.accumulationDetail
  }))
  const { type, accumulationDetail } = useSelector(AccumulationsProperties)

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (layout) => ({
    filter: layout.filter,
    zone: layout.zone
  }))
  const { filter, zone } = useSelector(LayoutProperties)
  const [lat, setLat] = useState()
  const [long, setLong] = useState()
  const [link, setLink] = useState()

  const isEdit = type !== 'view'

  const data = useMemo(() => {
    return accumulationDetail
  }, [accumulationDetail])

  const mapsData = (data) => {
    setLat(parseFloat(data?.userReward?.latitude ? data?.userReward?.latitude : 0).toFixed(6))
    setLong(parseFloat(data?.userReward?.longitude ? data?.userReward?.longitude : 0).toFixed(6))
    setLink(
      `https://www.google.com/maps?q=${parseFloat(data?.userReward?.latitude ? data?.userReward?.latitude : 0).toFixed(
        6
      )},${parseFloat(data?.userReward?.longitude ? data?.userReward?.longitude : 0).toFixed(6)}`
    )

    return filterDataHaveValue({
      serial: data?.product?.serial,
      model: data?.product?.modelId,
      category: data?.product?.category?.name ?? undefined,
      industrySector: data?.product?.industrySector?.name ?? undefined,
      industry: data?.product?.industry?.name ?? undefined,
      zone: data?.product?.zone?.name,
      channel: data?.product?.channel?.name,
      exportDate: formateDate(data?.product?.exportDate),
      //shop info
      shopName: data?.userReward?.user?.shopName,
      taxCode: data?.userReward?.user?.taxCode,
      phone: data?.userReward?.user?.phone,
      email: data?.userReward?.user?.email,
      province: data?.userReward?.user?.province?.name,
      district: data?.userReward?.user?.district?.name,
      ward: data?.userReward?.user?.ward?.name,
      address: data?.userReward?.user?.address,
      PIC: data?.userReward?.user?.name,
      cardId: data?.userReward?.user?.cardId,
      accumulationDate: formateDate(data?.userReward?.createdAt),
      status: data?.userReward?.status,
      link: `https://www.google.com/maps?q=${parseFloat(
        data?.userReward?.latitude ? data?.userReward?.latitude : 0
      ).toFixed(6)},${parseFloat(data?.userReward?.longitude ? data?.userReward?.longitude : 0).toFixed(6)}`,
      latitude: parseFloat(data?.userReward?.latitude ? data?.userReward?.latitude : 0).toFixed(6),
      longitude: parseFloat(data?.userReward?.longitude ? data?.userReward?.longitude : 0).toFixed(6),
      longlat: `(${parseFloat(data?.userReward?.latitude ? data?.userReward?.latitude : 0).toFixed(6)}, ${parseFloat(
        data?.userReward?.longitude ? data?.userReward?.longitude : 0
      ).toFixed(6)})`
    })
  }

  const onFinish = async (values) => {
    if (type !== 'view') {
      try {
        console.log('submit')
        if (type === 'edit') {
          const res = await updateAccumulationAPI({
            id: accumulationDetail.id,
            modelId: values?.model,
            userId: accumulationDetail?.userReward?.userId
          })
          if (res) {
            toast('Cập nhật thông tin tích lũy thành công.', { type: 'success' })
            dispatch(getAccumulationList({ ...filter, page: page }))
          }
        }

        onCloseClick()
      } catch (error) {
        console.log(error)
        toast(error.response.data.message, { type: 'error' })
      }
    } else {
      dispatch(setTypePopupAccumulation('edit'))
    }
  }

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true)
      },
      () => {
        setSubmittable(false)
      }
    )
  }, [watchForm])

  useEffect(() => {
    form.resetFields()
  }, [show])

  useEffect(() => {
    if (data) {
      form.setFieldsValue(mapsData(data))
      setInitData(mapsData(data))
    } else {
      form.resetFields()
    }
  }, [data])

  const getModelBySerial = async () => {
    const res = await getModelBySerialAPI(data?.product?.serial)
    console.log(res)
    setModel(res?.data)
  }

  useEffect(() => {
    getModelBySerial()
  }, [data])

  return (
    <Modal
      size='lg'
      isOpen={show}
      toggle={() => {
        onCloseClick()
      }}
      centered={true}
    >
      <div className='modal-content'>
        <ModalBody className='p-4 text-start'>
          <button type='button' onClick={onCloseClick} className='btn-close position-absolute end-0 top-0 m-3' />
          <h2>{type === 'edit' ? AccumulationVN.editAccumulation : AccumulationVN.viewAccumulation}</h2>
          <Form onFinish={onFinish} autoComplete='off' form={form} name='addEditAccumulationForm'>
            <div className='form-group pt-4'>
              <h5>{AccumulationVN.form.productInfo}</h5>

              <Row>
                <Col sm='6'>
                  <Label className='form-label'>
                    {AccumulationVN.form.serial}
                    <a className='text-danger'>*</a>
                  </Label>
                  <Form.Item name='serial' rules={[{ required: true, message: 'Vui lòng nhập Serial' }]}>
                    <FormInput disabled={type !== 'create'} maxLength={MAX_LENGTH_50} />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>
                    {AccumulationVN.form.model}
                    <a className='text-danger'>*</a>
                  </Label>
                  <Form.Item name='model' rules={[{ required: true, message: 'Vui lòng chọn model' }]}>
                    <FormSelect
                      placeholder={AccumulationVN.form.model.placeholder}
                      options={model?.map((item) => ({
                        value: item.id,
                        label: item.name
                      }))}
                      disabled={!isEdit}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <Label className='form-label'>{AccumulationVN.form.category}</Label>
                  <Form.Item name='category'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>{AccumulationVN.form.industrySector}</Label>
                  <Form.Item name='industrySector'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <Label className='form-label'>{AccumulationVN.form.industry}</Label>
                  <Form.Item name='industry'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>{AccumulationVN.form.zone}</Label>
                  <Form.Item name='zone'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <Label className='form-label'>{AccumulationVN.form.channel}</Label>
                  <Form.Item name='channel'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>{AccumulationVN.form.exportDate}</Label>
                  <Form.Item name='exportDate'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
              </Row>
              <h5>{AccumulationVN.form.shopInfo}</h5>

              <Row>
                <Col sm='6'>
                  <Label className='form-label'>{AccumulationVN.form.shopName}</Label>
                  <Form.Item name='shopName'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>{AccumulationVN.form.taxCode}</Label>
                  <Form.Item name='taxCode'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <Label className='form-label'>{AccumulationVN.form.phone}</Label>
                  <Form.Item name='phone'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>{AccumulationVN.form.email}</Label>
                  <Form.Item name='email'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <Label className='form-label'>{AccumulationVN.form.province}</Label>
                  <Form.Item name='province'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>{AccumulationVN.form.district}</Label>
                  <Form.Item name='district'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <Label className='form-label'>{AccumulationVN.form.ward}</Label>
                  <Form.Item name='ward'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>{AccumulationVN.form.address}</Label>
                  <Form.Item name='address'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <Label className='form-label'>{AccumulationVN.form.PIC}</Label>
                  <Form.Item name='PIC'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>{AccumulationVN.form.cardId}</Label>
                  <Form.Item name='cardId'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm='6'>
                  <Label className='form-label'>{AccumulationVN.form.accumulationDate}</Label>
                  <Form.Item name='accumulationDate'>
                    <FormInput disabled />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>{AccumulationVN.form.status}</Label>
                  <Form.Item name='status'>
                    <StatusAccumulation />
                  </Form.Item>
                </Col>
                <Col sm='6'>
                  <Label className='form-label'>{'Vị trí'}</Label>
                  <Form.Item name='longlat'>
                    {/* <FormInput disabled /> */}
                    <a href={`https://www.google.com/maps?q=${lat},${long}`} target='_blank' rel='noopener noreferrer'>
                      (`(${lat}, ${long})`)
                    </a>
                  </Form.Item>
                </Col>
                {accumulationDetail?.userReward?.isDeleted && (
                  <>
                    <Col sm='3'>
                      <Label className='form-label'> </Label>
                      <i
                        className='mdi mdi-recycle-variant'
                        style={{ color: 'red', fontSize: '50px' }}
                        id={`viewtooltip-bravo-${accumulationDetail.id}`}
                      />
                      <UncontrolledTooltip
                        trigger='hover'
                        placement='top'
                        target={`viewtooltip-bravo-${accumulationDetail.id}`}
                      >
                        Sản phẩm bị thu hồi.
                      </UncontrolledTooltip>
                    </Col>
                  </>
                )}
              </Row>
            </div>
            <div className='hstack gap-2 justify-content-center mt-4'>
              <button type='button' className='btn btn-soft-secondary w-25 ' onClick={onCloseClick}>
                Hủy
              </button>
            </div>
          </Form>
        </ModalBody>
      </div>
    </Modal>
  )
}
export default AddEditAccumulation
